import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Image } from 'semantic-ui-react';
import { IPublicUserProfile } from '../../data/interfaces';
import { GoalsService } from '../../services/goals-service';
import { UserService } from '../../services/user-service';
import './component-main-nav.scss';

interface IMainNavProps {
	activeItem: string;
	isSignedIn: boolean;
}

export function MainNav(props: IMainNavProps) {
	const [user, setUser] = React.useState({} as IPublicUserProfile);
	const getUser = async () => {
		const currentUser = await UserService.getCurrentUser();
		if (currentUser) {
			setUser(currentUser);
		}
	};
	React.useEffect(() => {
		if (!user) {
			getUser();
		}
	});

	return (
		<Menu secondary>
			<Menu.Item as={Link} name="Home" active={props.activeItem === 'home'} to={'/dashboard'} />
			<Menu.Item as={Link} name="Habits" active={props.activeItem === 'habits'} to={'/habits'} />
			<Menu.Item as={Link} name="Tasks" active={props.activeItem === 'tasks'} to={'/tasks'} />
			{props.isSignedIn && (
				<>
					<Menu.Item
						style={{ display: 'inline-block', cursor: 'pointer', float: 'right' }}
						onClick={() => UserService.logOut()}
					>
						<Image
							src={UserService.currentUser && UserService.currentUser.avatar}
							circular
							size="mini"
							style={{ display: 'inline-block', marginRight: '.5rem' }}
						/>{' '}
						Log out
					</Menu.Item>
					<Menu.Menu position="right">
						<Menu.Item
							position="right"
							name="Save"
							icon="cloud upload"
							onClick={() => GoalsService.saveToStorage()}
						/>
						<Menu.Item
							position="right"
							name="Load"
							icon="cloud download"
							onClick={() => GoalsService.fetchProfileFromStorage()}
						/>
					</Menu.Menu>
				</>
			)}
			{!props.isSignedIn && (
				<Menu.Item as={Link} to="/login">
					Log in
				</Menu.Item>
			)}
		</Menu>
	);
}
