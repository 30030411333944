import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { IProjectGoal, GoalType } from '../../data/interfaces';
import './component-goal-list.scss';
import { GoalsService } from '../../services/goals-service';
import { Link } from 'react-router-dom';
import { ModalNewGoal } from '../component-modal-new-goal';

export function GoalList(props: any) {
	const [update, setUpdate] = useState(0);
	const today = new Date();
	const updateList = () => {
		setUpdate(update + 1);
	};
	return (
		<div className={'goal-list'}>
			{props.goals &&
				props.goals
					.filter((g: IProjectGoal) => GoalsService.shouldGoalBeDisplayed(g, today))
					.map((g: IProjectGoal) => {
						return (
							<div key={g.id}>
								<div className={'top-level-goal'}>
									{g.goal.type === GoalType.GoodHabit ? (
										<Icon
											className={GoalsService.isGoalComplete(g) ? 'completed' : ''}
											name="thumbs up"
										></Icon>
									) : null}
									{g.goal.type === GoalType.BadHabit ? <Icon name="thumbs down"></Icon> : null}
									{g.goal.type === GoalType.Objective ? <Icon name="flag checkered"></Icon> : null}
									{g.goal.type === GoalType.OneTimeTask ? <Icon name="check"></Icon> : null}
									{g.goal.type === GoalType.Target ? <Icon name="bullseye"></Icon> : null}
									<Link to={`/goal/${g.id}`}>{g.goal.name}</Link>
									{g.hows.filter((g) => GoalsService.shouldGoalBeDisplayed(g, new Date())).length <
										3 &&
										g.goal.type !== GoalType.BadHabit && (
											<ModalNewGoal
												parentGoal={g}
												onUpdate={() => {
													updateList();
												}}
											/>
										)}
								</div>
								{g.hows
									.filter((h: IProjectGoal) => GoalsService.shouldGoalBeDisplayed(h, today))
									.map((h) => {
										return (
											<div key={h.id} className={'sub-goal'}>
												{h.goal.type === GoalType.GoodHabit ? (
													<Icon
														className={GoalsService.isGoalComplete(h) ? 'completed' : ''}
														name="thumbs up"
													></Icon>
												) : null}
												{h.goal.type === GoalType.BadHabit ? (
													<Icon name="thumbs down"></Icon>
												) : null}
												{h.goal.type === GoalType.Objective ? (
													<Icon name="flag checkered"></Icon>
												) : null}
												{h.goal.type === GoalType.OneTimeTask ? (
													<Icon name="check"></Icon>
												) : null}
												{h.goal.type === GoalType.Target ? <Icon name="bullseye"></Icon> : null}
												<Link to={`/goal/${h.id}`}>
													<span className={'sub-goal-name'}>
														{h.goal.name} {h.hows.length > 0 && <>({h.hows.length})</>}
													</span>
												</Link>
											</div>
										);
									})}
							</div>
						);
					})}
		</div>
	);
}
