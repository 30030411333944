import React, { useState } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { Container, Header } from 'semantic-ui-react';
import {
	substractDaysFromToday,
	isSameDay,
	isToday,
	parseInternationalDateString,
	getInternationalDateString
} from '../../utils/time-utils';
import './view-dashboard.scss';
import { GoalTree } from '../../components/component-goal-tree';
import { GoalsService } from '../../services/goals-service';
import { useHistory } from 'react-router-dom';
import { MainNav } from '../../components/component-main-nav';
import { UserService } from '../../services/user-service';

export function Dashboard(props: any) {
	const [updateUI, setUpdateUI] = useState(1);
	const history = useHistory();
	const nDays = 5;
	let selectedDate = new Date();
	if (props.match) {
		const dateStr = props.match?.params.date;
		if (dateStr && dateStr.length === 8) {
			selectedDate = parseInternationalDateString(dateStr);
		}
	}
	const setSelectedDate = (newDate: Date) => {
		history.push(`/dashboard/${getInternationalDateString(newDate)}`);
	};
	return (
		<Container className="dashboard">
			<MainNav activeItem="home" isSignedIn={!!UserService.currentUser} />
			<Header as="h1">
				{isToday(selectedDate) ? 'Today' : `${capitalizeFirstLetter(moment(selectedDate).fromNow())}`}
			</Header>
			<div className="date-selector">
				{_.times(nDays, (index) => {
					const date = substractDaysFromToday(nDays - (index + 1));
					const successPercent = getSuccessPercent(date);
					return (
						<div
							className={`date-selector-day ${isSameDay(date, selectedDate) ? 'active' : ''} ${
								successPercent <= 0 ? 'bad' : 'good'
							}`}
							onClick={() => setSelectedDate(date)}
						>
							<div className="date-selector-day-number">{date.getDate()}</div>
							<div className="date-selector-day-week">
								{moment(date).format('dddd').substring(0, 3).toUpperCase()}
							</div>
							<div className={`date-success`}>
								<div
									className={`date-success-percent`}
									style={{
										width: `${Math.round(
											(successPercent < 0 ? -1 * successPercent : successPercent) * 100
										)}%`
									}}
								></div>
							</div>
						</div>
					);
				})}
			</div>
			<GoalTree
				goals={GoalsService.getMyProfile().projects[0].goals}
				date={selectedDate}
				isTopLevelTree={true}
				onUpdate={() => {
					setUpdateUI(updateUI + 1);
				}}
			/>
		</Container>
	);
}

function capitalizeFirstLetter(text: string) {
	return text.substring(0, 1).toUpperCase() + text.substring(1);
}

function getSuccessPercent(date: Date): number {
	return GoalsService.getDayProgress(date);
}
