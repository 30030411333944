import React from 'react';
import './component-date-carousel.scss';
import { Button } from 'semantic-ui-react';
import { ProgressRing } from '../component-progress-ring';

export interface IDateCarouselProps {
	days: number;
	activeDate?: Date;
	dateChanged?: (date: Date) => void;
	ring1Percentages?: number[];
	ring2Percentages?: number[];
	ring1Color?: string;
	ring2Color?: string;
}

export function DateCarousel(props: IDateCarouselProps) {
	const today = new Date();
	const dates: Date[] = [];
	Array(props.days)
		.fill(0)
		.forEach((e, index) => {
			const newDate = new Date();
			newDate.setDate(today.getDate() - (props.days - index - 1));
			dates.push(newDate);
		});
	return (
		<div className="date-carousel">
			{dates.map((d, index) => {
				const isActive = isActiveDate(props.activeDate, d);
				return (
					<Button
						key={index}
						className={'date-item ' + (isActive ? 'active-date' : '')}
						onClick={() => props.dateChanged && props.dateChanged(d)}
					>
						{props.ring1Percentages && props.ring1Color && (
							<div className="ring1">
								<ProgressRing
									color={props.ring1Color}
									progress={props.ring1Percentages[index]}
									radius={isActive ? 45 : 35}
									stroke={3}
									flip={true}
								/>
							</div>
						)}
						{props.ring2Percentages && props.ring2Color && (
							<div className="ring2">
								<ProgressRing
									color={props.ring2Color}
									progress={props.ring2Percentages[index]}
									radius={isActive ? 42 : 32}
									stroke={3}
									flip={true}
								/>
							</div>
						)}

						<div className="date">{d.getDate()}</div>
						<div className="month">{d.toLocaleString('default', { month: 'short' })}</div>
					</Button>
				);
			})}
		</div>
	);
}

function isActiveDate(activeDate: Date | undefined, date: Date) {
	const dateToCompare = activeDate ?? new Date();
	return (
		dateToCompare.getDate() === date.getDate() &&
		dateToCompare.getMonth() === date.getMonth() &&
		dateToCompare.getFullYear() === date.getFullYear()
	);
}
