import { IPublicUserProfile, GoalType, GoalEventType } from '../data/interfaces';
import { substractDaysFromToday } from '../utils/time-utils';

export class MockService {
	public static mockProfile: IPublicUserProfile = {
		id: 'sachaa',
		name: 'Sacha',
		projects: [
			{
				id: 'sachaa-life-project',
				name: 'Life project',
				goals: [
					{
						id: 'bwp1t33pui',
						created: substractDaysFromToday(5),
						goal: {
							id: 'gbektlwu2p',
							type: GoalType.Objective,
							name: 'Turn dreams into reality',
							description: 'Enable people to make dreams come true'
						},
						hows: [
							{
								id: '899r4efy0s',
								created: substractDaysFromToday(5),
								goal: {
									id: 'i3jwhztkd6',
									type: GoalType.Objective,
									name: 'Build MVP',
									description: 'Build platform MVP'
								},
								hows: [],
								completed: false,
								archived: false
							},
							{
								id: 'd0bptnebf6',
								created: substractDaysFromToday(5),
								goal: {
									id: 'mnra27c5yb',
									type: GoalType.GoodHabit,
									name: 'Write daily blog post',
									description: 'Write daily blog post'
								},
								hows: [],
								completed: false,
								archived: false,
								history: [
									{
										id: 'd0bptnebf6-00000001',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(3)
									},
									{
										id: 'd0bptnebf6-00000002',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(2)
									},
									{
										id: 'd0bptnebf6-00000003',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(0)
									}
								]
							},
							{
								id: 's1yl19v2cu',
								created: substractDaysFromToday(5),
								goal: {
									id: '9h34ovz4f9',
									type: GoalType.Objective,
									name: 'Stay focused',
									description: 'Stay focused on task'
								},
								hows: [
									{
										id: 'sq1bbicy2g',
										created: substractDaysFromToday(5),
										goal: {
											id: '1uw03duu9x',
											type: GoalType.BadHabit,
											name: 'Procrastinate on social media',
											description: 'Procrastination leads to distractions'
										},
										hows: [],
										completed: false,
										archived: false,
										history: [
											{
												id: 'sq1bbicy2g-00000001',
												type: GoalEventType.SingleEvent,
												date: substractDaysFromToday(3)
											},
											{
												id: 'sq1bbicy2g-00000002',
												type: GoalEventType.SingleEvent,
												date: substractDaysFromToday(2)
											},
											{
												id: 'sq1bbicy2g-00000003',
												type: GoalEventType.SingleEvent,
												date: substractDaysFromToday(1)
											}
										]
									}
								],
								completed: false,
								archived: false
							}
						],
						completed: false,
						archived: false
					},
					{
						id: 'apspunbjui',
						created: substractDaysFromToday(5),
						goal: {
							id: 'iqvkmw861h',
							type: GoalType.Objective,
							name: 'Eat healthy',
							description: 'Eat healthy food'
						},
						hows: [
							{
								id: '0qdhqxrph3',
								created: substractDaysFromToday(5),
								goal: {
									id: 'cicv5354oj',
									type: GoalType.BadHabit,
									name: 'Eat junk food',
									description: 'Eat fast/junk food'
								},
								hows: [],
								completed: false,
								archived: false,
								history: [
									{
										id: '0qdhqxrph3-00000003',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(1)
									}
								]
							},
							{
								id: '0jssjwl4xv',
								created: substractDaysFromToday(5),
								goal: {
									id: 'm1x3ylxe4q',
									type: GoalType.BadHabit,
									name: 'Eat carbs at night',
									description: 'Eat carbs at night'
								},
								hows: [],
								completed: false,
								archived: false,
								history: [
									{
										id: '0jssjwl4xv-00000001',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(2)
									},
									{
										id: '0jssjwl4xv-00000002',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(3)
									}
								]
							},
							{
								id: '0jkp5it1hf',
								created: substractDaysFromToday(5),
								goal: {
									id: 'e3drdswvv3',
									type: GoalType.Objective,
									name: 'Eat vegetables',
									description: 'Eat vegetables'
								},
								hows: [],
								completed: false,
								archived: false,
								history: [
									{
										id: '0jkp5it1hf-00000001',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(3)
									},
									{
										id: '0jkp5it1hf-00000002',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(0)
									}
								]
							}
						],
						completed: false,
						archived: false
					},
					{
						id: '3sgw1uaxr1',
						created: substractDaysFromToday(5),
						goal: {
							id: 'xvew681s2h',
							type: GoalType.Objective,
							name: 'Happy family',
							description: 'Keep family happy'
						},
						hows: [
							{
								id: 'c2qly90lev',
								created: substractDaysFromToday(5),
								goal: {
									id: '8mpivosbqz',
									type: GoalType.GoodHabit,
									name: 'Play with kids',
									description: 'Play with kids'
								},
								hows: [],
								completed: false,
								archived: false,
								history: [
									{
										id: 'c2qly90lev-00000001',
										type: GoalEventType.SingleEvent,
										date: substractDaysFromToday(0)
									}
								]
							}
						],
						completed: false,
						archived: false
					}
				]
			}
		]
	};
}
