import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Container, Grid, Header, Icon, List } from 'semantic-ui-react';
import { MainNav } from '../../components/component-main-nav';
import { GoalType, IProjectGoal } from '../../data/interfaces';
import { GoalsService } from '../../services/goals-service';
import { UserService } from '../../services/user-service';
import {
	getInternationalDateString,
	isSameDay,
	parseInternationalDateString,
	substractDaysFromToday
} from '../../utils/time-utils';
import './view-tasks.scss';

export function Tasks(props: any) {
	const [updateUI, setUpdateUI] = useState(1);
	const history = useHistory();
	const nDays = 5;
	let selectedDate = new Date();
	if (props.match) {
		const dateStr = props.match?.params.date;
		if (dateStr && dateStr.length === 8) {
			selectedDate = parseInternationalDateString(dateStr);
		}
	}
	const setSelectedDate = (newDate: Date) => {
		history.push(`/tasks/${getInternationalDateString(newDate)}`);
	};

	const userGoals = GoalsService.getMyProfile().projects[0].goals.filter((g) =>
		GoalsService.shouldGoalBeDisplayed(g, selectedDate)
	);
	return (
		<Container className="tasks">
			<MainNav activeItem="tasks" isSignedIn={!!UserService.currentUser} />
			<Header as="h1">Tasks</Header>
			<div className="date-selector">
				{_.times(nDays, (index) => {
					const date = substractDaysFromToday(nDays - (index + 1));
					const successPercent = GoalsService.getDayProgress(date);
					return (
						<div
							className={`date-selector-day ${isSameDay(date, selectedDate) ? 'active' : ''} ${
								successPercent <= 0 ? 'bad' : 'good'
							}`}
							onClick={() => setSelectedDate(date)}
						>
							<div className="date-selector-day-number">{date.getDate()}</div>
							<div className="date-selector-day-week">
								{moment(date).format('dddd').substring(0, 3).toUpperCase()}
							</div>
							<div className={`date-success`}>
								<div
									className={`date-success-percent`}
									style={{
										width: `${Math.round(
											(successPercent < 0 ? -1 * successPercent : successPercent) * 100
										)}%`
									}}
								></div>
							</div>
						</div>
					);
				})}
			</div>
			{userGoals.map((g) => {
				const goalHows = GoalsService.getGoalsUnder(g, [GoalType.OneTimeTask], selectedDate);
				if (g.goal.type === GoalType.Objective) {
					if (goalHows.length > 0) {
						return (
							<div key={g.id} className={'goal-group'}>
								<Header as="h2">{g.goal.name}</Header>
								<List>
									{GoalsService.getGoalsUnder(g, [GoalType.OneTimeTask], selectedDate).map((h) => {
										return (
											<List.Item>
												<List.Content>
													<Task
														goal={h}
														date={selectedDate}
														onUpdate={() => setUpdateUI(updateUI + 1)}
													/>
												</List.Content>
											</List.Item>
										);
									})}
								</List>
							</div>
						);
					} else {
						return (
							<div key={g.id} className={'goal-group'}>
								<Header as="h2">{g.goal.name}</Header>
								<div className={'no-tasks'}>No tasks defined for this objective yet</div>
							</div>
						);
					}
				} else if (g.goal.type === GoalType.OneTimeTask) {
					return <Task goal={g} date={selectedDate} onUpdate={() => setUpdateUI(updateUI + 1)} />;
				} else {
					return <></>;
				}
			})}
		</Container>
	);
}

interface ITaskProps {
	goal: IProjectGoal;
	date?: Date;
	onUpdate: () => void;
}

function Task(props: ITaskProps) {
	const activeDate = props.date ?? new Date();
	const iconName: 'circle outline' | 'check circle outline' = GoalsService.wasCompletedOnDate(props.goal, activeDate)
		? 'check circle outline'
		: 'circle outline';
	const toggleTask = () => {
		GoalsService.touchGoal(props.goal.id, activeDate);
		props.onUpdate();
	};
	return (
		<div className={'task-item'}>
			<Grid columns={2}>
				<Grid.Row>
					<Grid.Column style={{ width: '3.6rem' }}>
						<Button circular icon onClick={() => toggleTask()}>
							<Icon name={iconName} size="big" />
						</Button>
					</Grid.Column>
					<Grid.Column style={{ width: 'calc(100% - 5rem)' }}>
						<div className="task-name">
							<Link to={`/goal/${props.goal.id}`}>{props.goal.goal.name}</Link>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
}
