import React, { useState } from 'react';
import _ from 'lodash';
import { Icon, Button, Form, Modal, Grid, Segment, Header, Input, Dropdown, Confirm } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DragStart,
	Droppable,
	DroppableProvided,
	DroppableStateSnapshot,
	DropResult,
	ResponderProvided
} from 'react-beautiful-dnd';
import './component-goal-tree.scss';
import { IProjectGoal, GoalType } from '../../data/interfaces';
import { GoalsService } from '../../services/goals-service';
import { ProgressRing } from '../component-progress-ring';
import { isToday, substractDaysFromDate } from '../../utils/time-utils';

// TODO: Create constants class
// const MAX_LEVEL_DEPTH = 10;

interface IGoalTreeProps {
	goals: IProjectGoal[];
	date: Date;
	isTopLevelTree?: boolean;
	onUpdate: () => void;
}

interface IGoalCombinationAction {
	isCombination: boolean;
	from?: IProjectGoal;
	to?: IProjectGoal;
	sourceDroppableId?: string;
	sourceIndex?: number;
	destinationDroppableId?: string;
	destinationIndex?: number;
}

interface INewQuickGoal {
	name?: string;
	type?: GoalType;
}

export function GoalTree(props: IGoalTreeProps) {
	const [collapsedGoals, setCollapsedGoals] = useState([] as IProjectGoal[]);
	const [confirmGoalCombine, setConfirmGoalCombine] = useState({ isCombination: false } as IGoalCombinationAction);
	const [newGoalDialogOpen, setNewGoalDialogOpen] = useState(false);
	const [newGoal, setNewGoal] = useState({ name: '', type: GoalType.Objective } as INewQuickGoal);
	const grid = 8;
	const displayedGoals = props.goals.filter((g) => GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals));

	const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragStart = (initial: DragStart, provided: ResponderProvided): void => {
		const goal = GoalsService.getGoal(initial.draggableId);
		const subGoalList: IProjectGoal[] = [];
		const getSubGoalList = (g: IProjectGoal) => {
			g.hows.forEach((h) => {
				subGoalList.push(h);
				getSubGoalList(h);
			});
		};
		if (goal) {
			getSubGoalList(goal);
			setCollapsedGoals(subGoalList);
		}
		/*
		const updatedDisabledDrops: boolean[] = userProfile.projects[0].goals.map((g) => {
			const parentGoal = GoalsService.parentGoal(initial.draggableId);
			return g.hows.length >= 3 && parentGoal?.id !== g.id;
		});
		setDisabledDroppers(updatedDisabledDrops);
		*/
	};

	const remove = (list: any[], deleteIndex: number): any[] => {
		const result = Array.from(list);
		result.splice(deleteIndex, 1);
		return result;
	};

	const onDragEnd = (result: DropResult, provided?: ResponderProvided) => {
		if (result.combine) {
			const draggedGoal = GoalsService.getGoal(result.draggableId);
			const droppedIntoGoal = GoalsService.getGoal(result.combine.draggableId);
			setConfirmGoalCombine({
				isCombination: true,
				from: draggedGoal,
				to: droppedIntoGoal,
				sourceDroppableId: GoalsService.parentGoal(draggedGoal?.id ?? '')?.id,
				sourceIndex: result.source.index,
				destinationDroppableId: GoalsService.parentGoal(droppedIntoGoal?.id ?? '')?.id,
				destinationIndex: GoalsService.parentGoal(droppedIntoGoal?.id ?? '')?.hows.findIndex(
					(h) => h.id === droppedIntoGoal?.id
				)
			});
		} else if (!result.destination) {
			return;
		} else {
			if (result.source.index !== result.destination.index) {
				const sourceGoal = GoalsService.getGoalFromIndex(result.source.index, props.date);
				if (sourceGoal) {
					const sourceGoalParent = GoalsService.getParentGoal(sourceGoal);
					const destinationGoal = GoalsService.getGoalFromIndex(result.destination.index, props.date);
					const destinationGoalNext = GoalsService.getGoalFromIndex(result.destination.index + 1, props.date);
					const destinationGoalPrev = GoalsService.getGoalFromIndex(result.destination.index - 1, props.date);
					let topGoal: IProjectGoal | undefined;
					let bottomGoal: IProjectGoal | undefined;
					let bottomGoalParent: IProjectGoal | undefined;
					let topGoalParent: IProjectGoal | undefined;

					if (result.source.index < result.destination.index) {
						topGoal = destinationGoal;
						bottomGoal = destinationGoalNext;
					} else {
						topGoal = destinationGoalPrev;
						bottomGoal = destinationGoal;
					}
					topGoalParent = GoalsService.getParentGoal(topGoal);
					bottomGoalParent = GoalsService.getParentGoal(bottomGoal);

					if (
						topGoal &&
						bottomGoal &&
						topGoalParent &&
						bottomGoalParent &&
						topGoalParent.id === bottomGoalParent.id
					) {
						// Top + Bottom are at the same level
						// Insert goal in common parent (topGoalParent)
						if (sourceGoalParent && sourceGoalParent.id === topGoalParent.id) {
							// Change order (remove and insert in new position)
							const indexOfSource = _.findIndex(props.goals, (g) => g.id === sourceGoal.id);
							const updatedHows = remove(topGoalParent.hows, indexOfSource);
							const topGoalIndex = _.findIndex(updatedHows, (g) => g.id === topGoal?.id);
							updatedHows.splice(topGoalIndex + 1, 0, sourceGoal);
							GoalsService.updateOrderOfSubGoals(topGoalParent.id, updatedHows);
						} else {
							// Remove from existing parent and insert in order
							if (
								topGoalParent.hows.filter((g) =>
									GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals)
								).length < 3
							) {
								// Remove from source
								if (sourceGoalParent) {
									const indexOfSource = _.findIndex(
										sourceGoalParent.hows,
										(h) => h.id === sourceGoal.id
									);
									const updatedHows = remove(sourceGoalParent.hows, indexOfSource);
									GoalsService.updateOrderOfSubGoals(sourceGoalParent.id, updatedHows);
								} else {
									// Remove from top level
									const indexOfSource = _.findIndex(props.goals, (g) => g.id === sourceGoal.id);
									const updatedGoals = remove(props.goals, indexOfSource);
									GoalsService.updateOrderOfTopGoals(updatedGoals);
								}

								// Insert goal
								const topGoalIndex = _.findIndex(topGoalParent.hows, (g) => g.id === topGoal?.id);
								topGoalParent.hows.splice(topGoalIndex + 1, 0, sourceGoal);
								GoalsService.updateOrderOfSubGoals(topGoalParent.id, topGoalParent.hows);
							}
						}
					} else if (bottomGoal && bottomGoalParent && topGoal && topGoal.id === bottomGoalParent.id) {
						// Bottom is child of Top
						// Insert goal under the top goal (topGoal)
						if (sourceGoalParent && sourceGoalParent.id === topGoal.id) {
							// Change order
							const indexOfSource = _.findIndex(topGoal.hows, (h) => h.id === sourceGoal.id);
							const updatedHows = remove(topGoal.hows, indexOfSource);
							updatedHows.unshift(sourceGoal);
							GoalsService.updateOrderOfSubGoals(topGoal.id, updatedHows);
						} else {
							if (
								topGoal.hows.filter((g) =>
									GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals)
								).length < 3
							) {
								// Remove from source
								if (sourceGoalParent) {
									const indexOfSource = _.findIndex(
										sourceGoalParent.hows,
										(h) => h.id === sourceGoal.id
									);
									const updatedHows = remove(sourceGoalParent.hows, indexOfSource);
									GoalsService.updateOrderOfSubGoals(sourceGoalParent.id, updatedHows);
								} else {
									// Remove from top level
									const indexOfSource = _.findIndex(props.goals, (g) => g.id === sourceGoal.id);
									const updatedGoals = remove(props.goals, indexOfSource);
									GoalsService.updateOrderOfTopGoals(updatedGoals);
								}

								topGoal.hows.unshift(sourceGoal);
								GoalsService.updateOrderOfSubGoals(topGoal.id, topGoal.hows);
							}
						}
					} else if (bottomGoal === undefined || topGoal === undefined) {
						// Either Top or Bottom are at the "edge" of the list
						// Insert goal as first level goal (first level)
						if (sourceGoalParent) {
							// Goal is not a top-level goal
							if (
								props.goals.filter((g) =>
									GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals)
								).length < 3
							) {
								// Remove from parent goal
								const indexOfSource = _.findIndex(sourceGoalParent.hows, (h) => h.id === sourceGoal.id);
								const updatedHows = remove(sourceGoalParent.hows, indexOfSource);
								GoalsService.updateOrderOfSubGoals(sourceGoalParent.id, updatedHows);

								const goalsAfterUpdate = GoalsService.userProfile.projects[0].goals;
								if (bottomGoal === undefined) {
									goalsAfterUpdate.push(sourceGoal);
								} else if (topGoal === undefined) {
									goalsAfterUpdate.unshift(sourceGoal);
								}
								GoalsService.updateOrderOfTopGoals(goalsAfterUpdate);
							}
						} else {
							// Source goal is also a top level goal
							// Remove from top level
							const indexOfSource = _.findIndex(props.goals, (g) => g.id === sourceGoal.id);
							const updatedGoals = remove(props.goals, indexOfSource);

							if (bottomGoal === undefined) {
								updatedGoals.push(sourceGoal);
							} else if (topGoal === undefined) {
								updatedGoals.unshift(sourceGoal);
							}
							GoalsService.updateOrderOfTopGoals(updatedGoals);
						}
					} else {
						// Top is from a different parent than Bottom
						// Insert goal above the bottom (same parent as bottom)
						if (bottomGoalParent) {
							// Bottom is not a top-level goal
							if (sourceGoalParent) {
								// TODO: Source is not a top-level goal
								if (sourceGoalParent.id === bottomGoalParent.id) {
									// They share same parent
									const indexOfSource = _.findIndex(
										bottomGoalParent.hows,
										(h) => h.id === sourceGoal.id
									);
									const updatedHows = remove(bottomGoalParent.hows, indexOfSource);
									const bottomGoalIndex = _.findIndex(updatedHows, (g) => g.id === bottomGoal?.id);
									if (bottomGoalIndex <= 0) {
										updatedHows.unshift(sourceGoal);
									} else {
										updatedHows.splice(bottomGoalIndex, 0, sourceGoal);
									}
									GoalsService.updateOrderOfSubGoals(bottomGoalParent.id, updatedHows);
								} else {
									// The goals come from different parents
									if (
										bottomGoalParent.hows.filter((g) =>
											GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals)
										).length < 3
									) {
										// Remove from source
										const indexOfSource = _.findIndex(
											sourceGoalParent.hows,
											(h) => h.id === sourceGoal.id
										);
										const updatedHows = remove(sourceGoalParent.hows, indexOfSource);
										GoalsService.updateOrderOfSubGoals(sourceGoalParent.id, updatedHows);

										const bottomGoalIndex = _.findIndex(
											bottomGoalParent.hows,
											(g) => g.id === bottomGoal?.id
										);
										if (bottomGoalIndex <= 0) {
											bottomGoalParent.hows.unshift(sourceGoal);
										} else {
											bottomGoalParent.hows.splice(bottomGoalIndex, 0, sourceGoal);
										}
										GoalsService.updateOrderOfSubGoals(bottomGoalParent.id, bottomGoalParent.hows);
									}
								}
							} else {
								// TODO: Source is a top-level goal
								if (
									bottomGoalParent.hows.filter((g) =>
										GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals)
									).length < 3
								) {
									const bottomGoalIndex = _.findIndex(
										bottomGoalParent.hows,
										(g) => g.id === bottomGoal?.id
									);
									if (bottomGoalIndex <= 0) {
										bottomGoalParent.hows.unshift(sourceGoal);
									} else {
										bottomGoalParent.hows.splice(bottomGoalIndex, 0, sourceGoal);
									}
									GoalsService.updateOrderOfSubGoals(bottomGoalParent.id, bottomGoalParent.hows);

									// Remove from goals
									const goalsAfterUpdate = GoalsService.userProfile.projects[0].goals;
									const indexOfSource = _.findIndex(goalsAfterUpdate, (g) => g.id === sourceGoal.id);
									const updatedGoals = remove(goalsAfterUpdate, indexOfSource);
									GoalsService.updateOrderOfTopGoals(updatedGoals);
								}
							}
						} else {
							// Bottom is a top-level goal
							if (sourceGoalParent) {
								// Source is not a top-level goal
								if (
									props.goals.filter((g) =>
										GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals)
									).length < 3
								) {
									// Remove from parent goal
									const indexOfSource = _.findIndex(
										sourceGoalParent.hows,
										(h) => h.id === sourceGoal.id
									);
									const updatedHows = remove(sourceGoalParent.hows, indexOfSource);
									GoalsService.updateOrderOfSubGoals(sourceGoalParent.id, updatedHows);

									// Add goal to bottom's parent
									const goalsAfterUpdate = GoalsService.userProfile.projects[0].goals;
									const bottomGoalIndex = _.findIndex(
										goalsAfterUpdate,
										(g) => g.id === bottomGoal?.id
									);
									if (bottomGoalIndex <= 0) {
										goalsAfterUpdate.unshift(sourceGoal);
									} else {
										goalsAfterUpdate.splice(bottomGoalIndex, 0, sourceGoal);
									}
									GoalsService.updateOrderOfTopGoals(goalsAfterUpdate);
								}
							} else {
								// Source is also a top-level goal
								// Change order (remove and insert in new position)
								const indexOfSource = _.findIndex(props.goals, (g) => g.id === sourceGoal.id);
								const updatedGoals = remove(props.goals, indexOfSource);
								const bottomGoalIndex = _.findIndex(updatedGoals, (g) => g.id === bottomGoal?.id);
								if (bottomGoalIndex <= 0) {
									updatedGoals.unshift(sourceGoal);
								} else {
									updatedGoals.splice(bottomGoalIndex, 0, sourceGoal);
								}
								GoalsService.updateOrderOfTopGoals(updatedGoals);
							}
						}
					}
					const newHows = reorder(props.goals, result.source.index, result.destination.index);
					GoalsService.updateOrderOfSubGoals(result.destination.droppableId, newHows);
				}
			}
			props.onUpdate();
		}
		setCollapsedGoals([]);
	};

	const createNewGoal = (
		parentGoal: IProjectGoal | undefined,
		goalA: IProjectGoal | undefined,
		goalB: IProjectGoal | undefined,
		name: string | undefined,
		type: GoalType | undefined
	) => {
		if (parentGoal && goalA && goalB && name && type !== undefined) {
			GoalsService.createGeneralizedGoal(parentGoal, goalA, goalB, name, type);
		}
		setNewGoal({ name: '', type: GoalType.Objective });
		setConfirmGoalCombine({ isCombination: false });
	};

	const addChild = (goalParent: IProjectGoal | undefined, goalChild: IProjectGoal | undefined) => {
		if (goalParent && goalChild) {
			if (
				goalParent.hows.filter((g) => GoalsService.shouldGoalBeDisplayed(g, props.date, collapsedGoals))
					.length < 3
			) {
				GoalsService.addSubGoalTo(goalParent.id, goalChild);
				if (confirmGoalCombine.sourceIndex && confirmGoalCombine.sourceDroppableId) {
					const parentGoal = GoalsService.getGoal(confirmGoalCombine.sourceDroppableId);
					const newHows = parentGoal?.hows.filter((g) => g.id !== goalChild.id);
					if (newHows) {
						GoalsService.updateOrderOfSubGoals(confirmGoalCombine.sourceDroppableId, newHows);
					}
				}
			}
		}
		setNewGoal({ name: '', type: GoalType.Objective });
		setConfirmGoalCombine({ isCombination: false });
	};

	const generalizeSelectedGoals = () => {
		const parentGoalSource = GoalsService.getGoal(confirmGoalCombine.sourceDroppableId ?? '');
		const parentGoalDestination = GoalsService.getGoal(confirmGoalCombine.sourceDroppableId ?? '');
		if (
			confirmGoalCombine.sourceIndex !== undefined &&
			confirmGoalCombine.destinationIndex !== undefined &&
			confirmGoalCombine.sourceIndex > confirmGoalCombine.destinationIndex
		) {
			const newSourceHows = remove(parentGoalSource?.hows ?? [], confirmGoalCombine.sourceIndex ?? -1);
			const newDestinationHows = remove(
				confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId
					? newSourceHows
					: parentGoalDestination?.hows ?? [],
				confirmGoalCombine.destinationIndex ?? -1
			);
			if (confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId) {
				GoalsService.updateOrderOfSubGoals(parentGoalSource?.id ?? '', newDestinationHows);
			} else {
				GoalsService.updateOrderOfSubGoals(parentGoalDestination?.id ?? '', newDestinationHows);
			}
		} else {
			const newDestinationHows = remove(
				parentGoalDestination?.hows ?? [],
				confirmGoalCombine.destinationIndex ?? -1
			);
			const newSourceHows = remove(
				confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId
					? newDestinationHows
					: parentGoalSource?.hows ?? [],
				confirmGoalCombine.sourceIndex ?? -1
			);
			if (confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId) {
				GoalsService.updateOrderOfSubGoals(parentGoalDestination?.id ?? '', newSourceHows);
			} else {
				GoalsService.updateOrderOfSubGoals(parentGoalSource?.id ?? '', newSourceHows);
			}
		}
		createNewGoal(
			GoalsService.getGoal(parentGoalDestination?.id ?? ''),
			confirmGoalCombine.to,
			confirmGoalCombine.from,
			newGoal.name,
			newGoal.type
		);
	};

	const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
		background: isDraggingOver ? 'lightblue' : 'lightgrey',
		padding: grid
	});

	const goalTypes = [
		{ key: 'objective', text: 'Objective', value: GoalType.Objective },
		{ key: 'good-habit', text: 'Good habit', value: GoalType.GoodHabit },
		{ key: 'bad-habit', text: 'Bad habit', value: GoalType.BadHabit },
		{ key: 'one-time', text: 'One-time task', value: GoalType.OneTimeTask },
		{ key: 'target', text: 'Target', value: GoalType.Target }
	];

	const addNewGoal = () => {
		setNewGoal({ name: '', type: GoalType.Objective });
		setNewGoalDialogOpen(true);
	};

	const addNewTopLevelGoal = () => {
		console.log(`Adding ${newGoal.name} (${newGoal.type})`);
		if (newGoal.name && newGoal.type !== undefined && newGoal.name.length > 2) {
			GoalsService.addTopLevelGoal(newGoal.name, newGoal.type);
			console.log(`Added`);
		}
		setNewGoal({ name: '', type: GoalType.Objective });
		setNewGoalDialogOpen(false);
		props.onUpdate();
	};

	return (
		<>
			<Modal
				className={'confirm-goal-combine'}
				onClose={() => setConfirmGoalCombine({ isCombination: false })}
				onOpen={() => setConfirmGoalCombine({ isCombination: false })}
				open={confirmGoalCombine.isCombination}
			>
				<Modal.Header>Combine goals</Modal.Header>
				<Modal.Content>
					<Grid columns={2} stackable>
						<Grid.Row verticalAlign="middle">
							<Grid.Column>
								<Segment>
									<Header>As parent and child</Header>
									<div className="tree-view">
										<div className="tree-view-parent">{confirmGoalCombine.to?.goal.name}</div>
										<div className="tree-view-child first-child">
											{confirmGoalCombine.from?.goal.name}
										</div>
									</div>
									<Button
										onClick={() => addChild(confirmGoalCombine.to, confirmGoalCombine.from)}
										primary
									>
										Accept
									</Button>
								</Segment>
							</Grid.Column>
							<Grid.Column>
								<Segment>
									<Header>New parent</Header>
									<div className="tree-view">
										<div className="tree-view-parent new-parent">
											<Input
												action={
													<Dropdown
														button
														basic
														floating
														options={goalTypes}
														onChange={(event, value) => {
															setNewGoal({
																name: newGoal.name,
																type: value.value as GoalType
															});
														}}
														value={newGoal.type}
													/>
												}
												value={newGoal.name}
												onChange={(event) =>
													setNewGoal({ name: event.target.value, type: newGoal.type })
												}
												style={{ width: '100%' }}
												actionPosition="left"
												placeholder="New goal..."
											/>
										</div>
										<div className="tree-view-child first-child">
											{confirmGoalCombine.to?.goal.name}
										</div>
										<div className="tree-view-child second-child">
											{confirmGoalCombine.from?.goal.name}
										</div>
									</div>
									<Button
										primary
										onClick={() => {
											generalizeSelectedGoals();
										}}
										disabled={newGoal.name === undefined || newGoal.name.trim().length <= 0}
									>
										Create
									</Button>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
			</Modal>
			<Modal className={'dialog-new-goal'} onClose={() => setNewGoalDialogOpen(false)} open={newGoalDialogOpen}>
				<Modal.Header>New goal</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Dropdown
							label="Type of goal"
							button
							basic
							floating
							options={goalTypes}
							onChange={(event, value) => {
								setNewGoal({
									name: newGoal.name,
									type: value.value as GoalType
								});
							}}
							value={newGoal.type}
						></Form.Dropdown>
						<Form.Input
							label="Goal name"
							placeholder="Briefly describe your goal"
							value={newGoal.name}
							onChange={(event) => setNewGoal({ name: event.target.value, type: newGoal.type })}
						></Form.Input>
						<Form.Group inline>
							<Form.Button type="submit" positive onClick={() => addNewTopLevelGoal()}>
								Add goal
							</Form.Button>
							<Form.Button standard onClick={() => setNewGoalDialogOpen(false)}>
								Cancel
							</Form.Button>
						</Form.Group>
					</Form>
				</Modal.Content>
			</Modal>
			{displayedGoals && displayedGoals.length > 0 ? (
				<>
					<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
						<Droppable isDropDisabled={false} droppableId={'goals'} isCombineEnabled>
							{(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
								<div
									className={'goal-tree'}
									style={getListStyle(snapshot.isDraggingOver)}
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{displayedGoals.map((g) => (
										<GoalTreeNode
											goal={g}
											date={props.date}
											level={0}
											onBranchUpdate={() => {
												props.onUpdate();
											}}
											hiddenWhileDragging={collapsedGoals}
										/>
									))}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					{displayedGoals.length < 3 && isToday(props.date) && props.isTopLevelTree && (
						<Button icon="plus" labelPosition="left" positive onClick={() => addNewGoal()}>
							<Icon name="plus" />
							Add goal
						</Button>
					)}
				</>
			) : isToday(props.date) && props.isTopLevelTree ? (
				<Segment className={'no-goals'}>
					<Header as="h3">Add your first goal</Header>
					<Button icon="plus" labelPosition="left" positive onClick={() => addNewGoal()}>
						<Icon name="plus" />
						Add goal
					</Button>
				</Segment>
			) : (
				<div className={'no-day-goals'}>No {props.isTopLevelTree ? 'goals' : 'sub-goals'} were defined yet</div>
			)}
		</>
	);
}

interface IGoalTreeNodeProps {
	goal: IProjectGoal;
	level?: number;
	date: Date;
	hiddenWhileDragging: IProjectGoal[];
	onBranchUpdate: () => void;
}

interface IShowAddNewSubGoal {
	goal: string | undefined;
}

interface IShowUpdateGoal {
	goal: string | undefined;
}

interface INewSubGoal {
	type: GoalType | undefined;
	name: string;
}

interface IBasicGoalDetails {
	type: GoalType | undefined;
	name: string;
}

export function GoalTreeNode(props: IGoalTreeNodeProps) {
	const [showAddNewSubGoal, setShowAddNewSubGoal] = useState({ goal: undefined } as IShowAddNewSubGoal);
	const [newSubgoal, setNewSubgoal] = useState({ type: undefined, name: '' } as INewSubGoal);
	const [showUpdateGoal, setShowUpdateGoal] = useState({ goal: undefined } as IShowUpdateGoal);
	const [updatedGoal, setUpdatedGoal] = useState({
		type: props.goal.goal.type,
		name: props.goal.goal.name
	} as IBasicGoalDetails);
	const [showAddPurpose, setShowAddPurpose] = useState(false);
	const [purposeName, setPurposeName] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const goalProgress = GoalsService.getGoalProgress(props.goal);
	const DAY_TICK_COUNT = 7;
	const addSubGoal = () => {
		if (props.goal && newSubgoal.name.length > 0 && newSubgoal.type !== undefined) {
			GoalsService.addSimpleNewSubGoalTo(props.goal, newSubgoal.name, newSubgoal.type);
		}
		setShowAddNewSubGoal({ goal: undefined });
		setNewSubgoal({ type: undefined, name: '' });
		props.onBranchUpdate();
	};
	const updateGoal = () => {
		if (props.goal && updatedGoal.name.length > 0 && updatedGoal.type !== undefined) {
			GoalsService.updateGoal(props.goal.id, updatedGoal.name, updatedGoal.type);
		}
		setShowUpdateGoal({ goal: undefined });
		setUpdatedGoal({ type: updatedGoal.type, name: updatedGoal.name });
		props.onBranchUpdate();
	};
	const hideWhileDragging = (goal: IProjectGoal) => {
		return _.some(props.hiddenWhileDragging, (g) => g.id === goal.id);
	};

	const grid = 8;

	const getItemStyle = (
		isDragging: boolean,
		draggableStyle: React.CSSProperties | undefined
	): React.CSSProperties => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		background: isDragging ? 'lightgreen' : '#ccc',

		// styles we need to apply on draggables
		...draggableStyle
	});

	const archiveGoal = (goal: IProjectGoal) => {
		goal && GoalsService.archiveGoal(goal);
		setConfirmDelete(false);
		props.onBranchUpdate();
	};

	const addPurpose = () => {
		props.goal && GoalsService.addPurposeToGoal(props.goal.id, purposeName);
		setPurposeName('');
		setShowAddPurpose(false);
		props.onBranchUpdate();
	};

	return (
		<>
			<Confirm
				content="Are you sure you want to delete this goal?"
				open={confirmDelete}
				onCancel={() => setConfirmDelete(false)}
				onConfirm={() => archiveGoal(props.goal)}
			/>
			<Modal className={'modal-add-purpose'} onClose={() => setShowAddPurpose(false)} open={showAddPurpose}>
				<Modal.Header>New purpose</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Input
							label="Goal name"
							placeholder="Briefly describe your goal"
							value={purposeName}
							onChange={(event) => setPurposeName(event.target.value)}
						></Form.Input>
						<Form.Group inline>
							<Form.Button type="submit" positive onClick={() => addPurpose()}>
								Add purpose
							</Form.Button>
							<Form.Button standard onClick={() => setShowAddPurpose(false)}>
								Cancel
							</Form.Button>
						</Form.Group>
					</Form>
				</Modal.Content>
			</Modal>
			<Draggable
				key={props.goal.id}
				draggableId={props.goal.id}
				index={GoalsService.getGoalIndex(props.goal, props.date, props.hiddenWhileDragging)}
			>
				{(provided: DraggableProvided, snapshot: any) => (
					<>
						<div
							className={`goal-item ${hideWhileDragging(props.goal) ? 'hidden' : ''}`}
							ref={provided.innerRef}
							{...provided.draggableProps}
							style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
						>
							<div className={'goal-drag-handle'} {...provided.dragHandleProps}>
								<Icon name="ellipsis vertical" />
							</div>
							<div className={'goal-item-content'}>
								<div className={'goal-item-name'}>
									<Spacer level={props.level ?? 0}></Spacer>
									<div className={'goal-item-progress'}>
										{goalProgress >= 0 ? (
											props.goal.goal.type === GoalType.BadHabit ? (
												<ProgressRing
													radius={16}
													stroke={3}
													progress={goalProgress}
													color={'#EB5757'}
													flip={true}
												/>
											) : (
												<ProgressRing
													radius={16}
													stroke={3}
													progress={goalProgress}
													color={'#6FCF97'}
													flip={true}
												/>
											)
										) : (
											<ProgressRing
												radius={16}
												stroke={3}
												progress={-1 * goalProgress}
												color={'#EB5757'}
												flip={false}
											/>
										)}
									</div>
									<Link to={`/goal/${props.goal.id}`} className={'goal-item-name-link'}>
										<span className={`${props.level === 0 ? 'top-goal' : ''}`}>
											{props.goal.goal.name}
										</span>
										{false &&
											` (${GoalsService.getGoalIndex(
												props.goal,
												props.date,
												props.hiddenWhileDragging
											)})`}
										{false && `${hideWhileDragging(props.goal) ? ' HIDDEN' : ' - not hidden'}`}
									</Link>
									{showAddNewSubGoal && showAddNewSubGoal.goal === undefined && (
										<>
											<Dropdown
												button
												direction="left"
												className="goal-add icon"
												icon="ellipsis horizontal"
											>
												<Dropdown.Menu>
													{(props.goal.goal.type === GoalType.OneTimeTask ||
														props.goal.goal.type === GoalType.GoodHabit ||
														props.goal.goal.type === GoalType.BadHabit) && (
														<Dropdown.Item
															onClick={() => {
																GoalsService.touchGoal(props.goal.id, props.date);
																props.onBranchUpdate();
															}}
														>
															<Icon name="check" />
															{props.goal.completed
																? 'Mark as incomplete'
																: 'Mark as complete'}
														</Dropdown.Item>
													)}
													{props.goal.goal.type === GoalType.Objective && (
														<Dropdown.Item
															disabled={
																props.goal.hows.filter((g: IProjectGoal) =>
																	GoalsService.shouldGoalBeDisplayed(
																		g,
																		props.date,
																		props.hiddenWhileDragging
																	)
																).length >= 3
															}
															onClick={() => {
																if (
																	showAddNewSubGoal.goal &&
																	showAddNewSubGoal.goal === props.goal.id
																) {
																	setShowAddNewSubGoal({ goal: undefined });
																} else {
																	setShowAddNewSubGoal({ goal: props.goal.id });
																}
															}}
														>
															<Icon name="plus" />
															Add sub-goal (how)
														</Dropdown.Item>
													)}
													<Dropdown.Item
														onClick={() => {
															setShowAddPurpose(true);
														}}
													>
														<Icon name="sitemap" />
														Add purpose (why)
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															if (
																showUpdateGoal.goal &&
																showUpdateGoal.goal === props.goal.id
															) {
																setShowUpdateGoal({ goal: undefined });
															} else {
																setShowUpdateGoal({ goal: props.goal.id });
															}
														}}
													>
														<Icon name="edit" />
														Edit
													</Dropdown.Item>
													<Dropdown.Item onClick={() => setConfirmDelete(true)}>
														<Icon name="trash" />
														Delete
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</Draggable>
			{showUpdateGoal.goal && showUpdateGoal.goal === props.goal.id && (
				<Draggable
					key={props.goal.id}
					draggableId={props.goal.id}
					index={GoalsService.getGoalIndex(props.goal, props.date, props.hiddenWhileDragging)}
				>
					{(provided: DraggableProvided, snapshot: any) => (
						<div className={'goal-item'}>
							<div className={'goal-item-content'}>
								<div className="goal-inline-form">
									<Spacer level={props.level ?? 0}></Spacer>
									<div className="inline-add-new-subgoal">
										<Form>
											<Form.Group widths="equal">
												<Form.Select
													width={4}
													name="type"
													label="Type"
													value={updatedGoal.type}
													onChange={(e, v) =>
														setUpdatedGoal({
															type: v.value as GoalType,
															name: updatedGoal.name
														})
													}
													options={[
														{ key: '0', value: GoalType.Objective, text: 'Objective' },
														{ key: '1', value: GoalType.GoodHabit, text: 'Good habit' },
														{ key: '2', value: GoalType.BadHabit, text: 'Bad habit' },
														{ key: '3', value: GoalType.OneTimeTask, text: 'Task' },
														{ key: '4', value: GoalType.Target, text: 'Target' }
													]}
													placeholder="Goal type"
												/>
												<Form.Input
													width={12}
													name="name"
													value={updatedGoal.name}
													onChange={(e) =>
														setUpdatedGoal({ type: updatedGoal.type, name: e.target.value })
													}
													label="What is the goal?"
													placeholder="Your big next goal..."
												/>
											</Form.Group>
											<Form.Group className="inline-form-cta">
												<Button
													basic
													onClick={() => {
														setShowUpdateGoal({ goal: undefined });
														setUpdatedGoal({ type: undefined, name: '' });
													}}
												>
													Cancel
												</Button>
												<Button primary onClick={updateGoal}>
													Save
												</Button>
											</Form.Group>
										</Form>
									</div>
								</div>
							</div>
						</div>
					)}
				</Draggable>
			)}

			{showAddNewSubGoal.goal && showAddNewSubGoal.goal === props.goal.id && (
				<Draggable
					key={props.goal.id}
					draggableId={props.goal.id}
					index={GoalsService.getGoalIndex(props.goal, props.date, props.hiddenWhileDragging)}
				>
					{(provided: DraggableProvided, snapshot: any) => (
						<div className={'goal-item'}>
							<div className={'goal-item-content'}>
								<div className="goal-inline-form">
									<Spacer level={props.level ?? 0}></Spacer>
									<div className="inline-add-new-subgoal">
										<Form>
											<Form.Group widths="equal">
												<Form.Select
													width={4}
													name="type"
													label="Type"
													value={newSubgoal.type}
													onChange={(e, v) =>
														setNewSubgoal({
															type: v.value as GoalType,
															name: newSubgoal.name
														})
													}
													options={[
														{ key: '0', value: GoalType.Objective, text: 'Objective' },
														{ key: '1', value: GoalType.GoodHabit, text: 'Good habit' },
														{ key: '2', value: GoalType.BadHabit, text: 'Bad habit' },
														{ key: '3', value: GoalType.OneTimeTask, text: 'Task' },
														{ key: '4', value: GoalType.Target, text: 'Target' }
													]}
													placeholder="Goal type"
												/>
												<Form.Input
													width={12}
													name="name"
													value={newSubgoal.name}
													onChange={(e) =>
														setNewSubgoal({ type: newSubgoal.type, name: e.target.value })
													}
													label="What is the goal?"
													placeholder="Your big next goal..."
												/>
											</Form.Group>
											<Form.Group className="inline-form-cta">
												<Button
													basic
													onClick={() => {
														setShowAddNewSubGoal({ goal: undefined });
														setNewSubgoal({ type: undefined, name: '' });
													}}
												>
													Cancel
												</Button>
												<Button primary onClick={addSubGoal}>
													Save
												</Button>
											</Form.Group>
										</Form>
									</div>
								</div>
							</div>
						</div>
					)}
				</Draggable>
			)}
			{props.goal.hows.length > 0 &&
				props.goal.hows
					.filter((h) => GoalsService.shouldGoalBeDisplayed(h, props.date, props.hiddenWhileDragging))
					.map((h, hindex) => (
						<GoalTreeNode
							goal={h}
							key={hindex}
							level={props.level ? props.level + 1 : 1}
							date={props.date}
							onBranchUpdate={() => {
								props.onBranchUpdate();
							}}
							hiddenWhileDragging={props.hiddenWhileDragging}
						/>
					))}
		</>
	);
}

interface IAddSubGoalCTAProps {
	goal: IProjectGoal;
	showAddNewSubGoal: IShowAddNewSubGoal;
	setShowAddNewSubGoal: (ans: { goal: any }) => void;
	hiddenWhileDragging: IProjectGoal[];
	date: Date;
}

function AddSubGoalCTA(props: IAddSubGoalCTAProps) {
	return (
		<Button
			disabled={
				props.goal.hows.filter((g: IProjectGoal) =>
					GoalsService.shouldGoalBeDisplayed(g, props.date, props.hiddenWhileDragging)
				).length >= 3
			}
			onClick={() => {
				if (props.showAddNewSubGoal.goal && props.showAddNewSubGoal.goal === props.goal.id) {
					props.setShowAddNewSubGoal({ goal: undefined });
				} else {
					props.setShowAddNewSubGoal({ goal: props.goal.id });
				}
			}}
			className={`goal-add`}
		>
			{props.showAddNewSubGoal.goal && props.showAddNewSubGoal.goal === props.goal.id ? (
				<Icon name="minus" />
			) : (
				<Icon name="plus" />
			)}
		</Button>
	);
}

function Spacer(props: any) {
	const indentSpace = 20;
	const leftPadding = 20;
	return (
		<div
			style={{
				width: `${leftPadding + props.level * indentSpace}px`,
				height: '10px',
				display: 'inline-block',
				verticalAlign: 'top',
				margin: 0
			}}
		></div>
	);
}

/*
function getGoalIconName(goal: IProjectGoal): any {
	switch (goal.goal.type) {
		case GoalType.GoodHabit:
			return 'thumbs up';
		case GoalType.BadHabit:
			return 'thumbs down';
		case GoalType.Objective:
			return 'checkered flag';
		case GoalType.OneTimeTask:
			return 'check';
		case GoalType.Target:
			return 'bullseye';
	}
}
*/
