import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Container } from 'semantic-ui-react';
import './view-login.scss';
import { UserService } from '../../services/user-service';

export class LogIn extends React.Component {
	// Configure FirebaseUI.
	uiConfig: firebaseui.auth.Config = {
		signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.GoogleAuthProvider.PROVIDER_ID],
		callbacks: {
			signInSuccessWithAuthResult: (res, redirect) => {
				console.log(res, redirect);
				return this.onSignInSuccess();
			},
			signInFailure: async (err) => {
				console.error(err);
			}
		},
		signInSuccessUrl: '/dashboard'
	};

	onSignInSuccess(): boolean {
		UserService.getCurrentUser();
		return true;
	}

	render() {
		return (
			<Container>
				<StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
			</Container>
		);
	}
}
