import moment from 'moment';

export const substractDaysFromToday = (n: number): Date => {
	return substractDaysFromDate(new Date(), n);
};

export const substractDaysFromDate = (date: Date, n: number): Date => {
	const d = new Date(date);
	d.setDate(d.getDate() - n);
	return new Date(d);
};

export const isToday = (someDate: Date): boolean => {
	return isSameDay(someDate, new Date());
};

export const isSameDay = (dayA: Date, dayB: Date): boolean => {
	return (
		new Date(dayA).getDate() === new Date(dayB).getDate() &&
		new Date(dayA).getMonth() === new Date(dayB).getMonth() &&
		new Date(dayA).getFullYear() === new Date(dayB).getFullYear()
	);
};

export const isLastHour = (someDate: Date): boolean => {
	const now = new Date();
	if (isToday(someDate)) {
		return someDate.getHours() >= now.getHours() - 1;
	}
	return false;
};

export const isFuture = (date: Date): boolean => {
	return isFutureFromDate(date, new Date());
};

export const isFutureFromDate = (date: Date, from: Date): boolean => {
	let newFrom = from;
	newFrom.setDate(newFrom.getDate() + 1);
	newFrom = new Date(newFrom.getFullYear(), newFrom.getMonth(), newFrom.getDate());
	return new Date(date) >= newFrom;
};

export const isDateOnSameDateOrLater = (date: Date, from: Date): boolean => {
	const dateDate = new Date(date);
	const fromDate = new Date(from);
	const dateMidnight = new Date(dateDate.getFullYear(), dateDate.getMonth(), dateDate.getDate());
	const fromMidnight = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
	return dateMidnight <= fromMidnight;
};

export const isTodayOrFutureFromDate = (date: Date, from: Date): boolean => {
	let newFrom = new Date(from);
	newFrom = new Date(newFrom.getFullYear(), newFrom.getMonth(), newFrom.getDate());
	return new Date(date) >= newFrom;
};

export const parseInternationalDateString = (dateStr: string): Date => {
	let date = new Date();
	if (dateStr.length === 8) {
		const year = Number.parseInt(dateStr.substring(0, 4));
		const month = Number.parseInt(dateStr.substring(4, 6)) - 1;
		const day = Number.parseInt(dateStr.substring(6, 8));
		date = new Date(year, month, day);
	}
	return date;
};

export const getInternationalDateString = (date: Date): string => {
	return moment(date).format('YYYYMMDD');
};
