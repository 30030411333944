import React from 'react';
import firebase from 'firebase/app';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

import { Landing } from './views/landing';
import { Home } from './views/home';
import { Checkpoint } from './views/checkpoint';
import { Goal } from './views/goal';
import { Dreams } from './views/dreams';
import { Dashboard } from './views/dashboard';
import { Habits } from './views/habits';
import { Tasks } from './views/tasks';
import { LogIn } from './views/login';
import { UserService } from './services/user-service';

// Configure Firebase.
const firebaseConfig = {
	apiKey: 'AIzaSyDs6RBdYpY0cqoPkphBSYjskdUWm6fq5EU',
	authDomain: 'telos3-2020.firebaseapp.com',
	databaseURL: 'https://telos3-2020.firebaseio.com',
	projectId: 'telos3-2020',
	storageBucket: 'telos3-2020.appspot.com',
	messagingSenderId: '299929504096',
	appId: '1:299929504096:web:3f91711478f1aa546cf605',
	measurementId: 'G-BRMXYKGHC9'
};
firebase.initializeApp(firebaseConfig);

interface IAppState {
	isSignedIn: boolean;
}

class App extends React.Component<any, IAppState> {
	unregisterAuthObserver: firebase.Unsubscribe | null = null;

	constructor(props: any) {
		super(props);
		this.state = {
			isSignedIn: false // Local signed-in state.
		};
	}

	componentDidMount() {
		this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
			await UserService.getCurrentUser();
			this.setState({
				isSignedIn: !!user
			});
		});
	}

	componentWillUnmount() {
		this.unregisterAuthObserver && this.unregisterAuthObserver();
	}

	render() {
		return (
			<Router>
				<Switch>
					<Route path="/checkpoint">
						<Checkpoint />
					</Route>
					<Route path="/home">
						<Home />
					</Route>
					<Route path="/login">
						<LogIn />
					</Route>
					<Route path="/dreams">
						<Dreams />
					</Route>
					<Route path="/habits/:date" render={({ match }) => <Habits match={match} />} />
					<Route path="/habits">
						<Habits />
					</Route>
					<Route path="/tasks/:date" render={({ match }) => <Tasks match={match} />} />
					<Route path="/tasks">
						<Tasks />
					</Route>
					<Route path="/dashboard/:date" render={({ match }) => <Dashboard match={match} />} />
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route path="/goal/:id" render={({ match }) => <Goal match={match} />}></Route>
					<Route path="/">
						<Landing />
					</Route>
				</Switch>
			</Router>
		);
	}
}

export default App;
