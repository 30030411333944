import * as firebase from 'firebase/app';
import { IPublicUserProfile } from '../data/interfaces';
import { GoalsService } from './goals-service';

export class UserService {
	public static currentUser: IPublicUserProfile | null | undefined = undefined;

	public static async getCurrentUser(): Promise<IPublicUserProfile | null | undefined> {
		const firebaseUser = firebase.auth().currentUser;
		if (firebaseUser) {
			if (firebaseUser) {
				UserService.currentUser = {
					id: firebaseUser.uid,
					name: firebaseUser.displayName ?? '',
					avatar: firebaseUser.photoURL ?? '',
					projects: GoalsService.getMyProfile().projects
				};
			}
		} else {
			UserService.currentUser = null;
		}
		return UserService.currentUser;
	}

	public static async logOut() {
		UserService.currentUser = undefined;
		await firebase.auth().signOut();
	}
}
