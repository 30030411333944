export class QuotesService {
	static getQuote(): string {
		const quoteList: string[] = [
			"If it doesn't challenge you, it won't change you.",
			'The man who moves a mountain begins by carrying away small stones.',
			'Patience is the key which solves all problems.',
			'Never give up, no matter what.',
			'If you can imagine it, you can achieve it.',
			'Never give up.',
			'Do or do not. There is no try.',
			'Be thankful for what you have.',
			"You don't have to be great to start, but you have to start to be great.",
			"If you're going through hell, keep going.",
			'Keep your eyes on the prize.',
			'A journey of a thousand miles begins with a single step.',
			'A goal without a plan is just a wish.',
			"It's the hard that makes it great.",
			"If you can't win, don't lose.",
			'That which does not kill us, makes us stronger.'
		];
		return quoteList[Math.floor(Math.random() * quoteList.length)];
	}
}
