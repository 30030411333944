import React from 'react';
import './component-progress-ring.scss';

export interface IProgressRingProps {
	radius: number;
	stroke: number;
	progress: number;
	color?: string;
	flip?: boolean;
}

export function ProgressRing(props: IProgressRingProps) {
	const { radius, stroke, progress } = props;
	const normalizedRadius = radius - stroke * 2;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = Math.floor(progress * circumference);

	return (
		<svg className="progress-ring" height={radius * 2} width={radius * 2}>
			<circle
				stroke="rgba(255,255,255,.6)"
				fill="transparent"
				strokeWidth={stroke}
				r={normalizedRadius}
				cx={radius}
				cy={radius}
			/>
			<circle
				stroke={props.color ?? 'white'}
				fill="rgba(255,255,255,.2)"
				strokeWidth={stroke}
				strokeDasharray={strokeDashoffset + ' ' + (circumference - strokeDashoffset)}
				style={{ strokeDashoffset }}
				r={normalizedRadius}
				cx={radius}
				cy={radius}
				transform={
					props.flip
						? `translate(${radius * 2},0) scale(-1,1) rotate(-90 ${radius} ${radius})`
						: `rotate(-90 ${radius} ${radius})`
				}
			/>
		</svg>
	);
}
