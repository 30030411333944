import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Container, Header, Icon, List } from 'semantic-ui-react';
import { MainNav } from '../../components/component-main-nav';
import { GoalType, IProjectGoal } from '../../data/interfaces';
import { GoalsService } from '../../services/goals-service';
import { UserService } from '../../services/user-service';
import {
	getInternationalDateString,
	isSameDay,
	parseInternationalDateString,
	substractDaysFromDate,
	substractDaysFromToday
} from '../../utils/time-utils';
import './view-habits.scss';

export function Habits(props: any) {
	const [updateUI, setUpdateUI] = useState(1);
	const history = useHistory();
	const nDays = 5;
	let selectedDate = new Date();
	if (props.match) {
		const dateStr = props.match?.params.date;
		if (dateStr && dateStr.length === 8) {
			selectedDate = parseInternationalDateString(dateStr);
		}
	}
	const setSelectedDate = (newDate: Date) => {
		history.push(`/habits/${getInternationalDateString(newDate)}`);
	};
	return (
		<Container className="habits">
			<MainNav activeItem="habits" isSignedIn={!!UserService.currentUser} />
			<Header as="h1">Habits</Header>
			<div className="date-selector">
				{_.times(nDays, (index) => {
					const date = substractDaysFromToday(nDays - (index + 1));
					const successPercent = GoalsService.getDayProgress(date);
					return (
						<div
							className={`date-selector-day ${isSameDay(date, selectedDate) ? 'active' : ''} ${
								successPercent <= 0 ? 'bad' : 'good'
							}`}
							onClick={() => setSelectedDate(date)}
						>
							<div className="date-selector-day-number">{date.getDate()}</div>
							<div className="date-selector-day-week">
								{moment(date).format('dddd').substring(0, 3).toUpperCase()}
							</div>
							<div className={`date-success`}>
								<div
									className={`date-success-percent`}
									style={{
										width: `${Math.round(
											(successPercent < 0 ? -1 * successPercent : successPercent) * 100
										)}%`
									}}
								></div>
							</div>
						</div>
					);
				})}
			</div>
			{GoalsService.getMyProfile()
				.projects[0].goals.filter((g) => GoalsService.shouldGoalBeDisplayed(g, selectedDate))
				.map((g) => {
					const goalHows = GoalsService.getGoalsUnder(
						g,
						[GoalType.GoodHabit, GoalType.BadHabit],
						selectedDate
					);
					if (g.goal.type === GoalType.Objective) {
						if (goalHows.length > 0) {
							return (
								<div key={g.id}>
									<Header as="h2">{g.goal.name}</Header>
									<List>
										{GoalsService.getGoalsUnder(
											g,
											[GoalType.GoodHabit, GoalType.BadHabit],
											selectedDate
										)
											.sort((a, b) =>
												a.history && b.history
													? a.history.length > b.history.length
														? -1
														: 1
													: 0
											)
											.map((h) => {
												return (
													<List.Item>
														<List.Content>
															<HabitTimeline
																goal={h}
																date={selectedDate}
																onUpdate={() => setUpdateUI(updateUI + 1)}
															/>
														</List.Content>
													</List.Item>
												);
											})}
									</List>
								</div>
							);
						} else {
							return (
								<div key={g.id} className={'goal-group'}>
									<Header as="h2">{g.goal.name}</Header>
									<div className={'no-tasks'}>No habits defined for this objective yet</div>
								</div>
							);
						}
					} else if (g.goal.type === GoalType.BadHabit || g.goal.type === GoalType.GoodHabit) {
						return (
							<HabitTimeline goal={g} date={selectedDate} onUpdate={() => setUpdateUI(updateUI + 1)} />
						);
					} else {
						return <></>;
					}
				})}
		</Container>
	);
}

interface IHabitTimelineProps {
	goal: IProjectGoal;
	date?: Date;
	onUpdate: () => void;
}

function HabitTimeline(props: IHabitTimelineProps) {
	const DAY_TICK_COUNT = 20;
	const activeDate = props.date ?? new Date();
	const toggleHabit = () => {
		GoalsService.touchGoal(props.goal.id, activeDate);
		props.onUpdate();
	};
	return (
		<div className={'habit-timeline'}>
			<div>
				<Link to={`/goal/${props.goal.id}`}>{props.goal.goal.name}</Link>
			</div>
			<div className="progress-bar">
				{_.range(DAY_TICK_COUNT).map((n) => {
					if (n === DAY_TICK_COUNT - 1) {
						return (
							<div
								className={`habit-check ${
									GoalsService.wasCompletedOnDate(
										props.goal,
										substractDaysFromDate(activeDate, DAY_TICK_COUNT - n - 1)
									)
										? 'done'
										: ''
								} ${props.goal.goal.type === GoalType.BadHabit ? 'negative' : 'positive'}`}
							>
								<Button icon="check" onClick={() => toggleHabit()}>
									<Icon name="check"></Icon>
								</Button>
							</div>
						);
					} else {
						return (
							<div
								className={`progress-bar-tick ${
									GoalsService.wasCompletedOnDate(
										props.goal,
										substractDaysFromDate(activeDate, DAY_TICK_COUNT - n - 1)
									)
										? 'done'
										: ''
								} ${props.goal.goal.type === GoalType.BadHabit ? 'negative' : 'positive'}`}
							></div>
						);
					}
				})}
			</div>
		</div>
	);
}
