import React from 'react';
import { Container, Grid, Header, Image } from 'semantic-ui-react';
import { QuotesService } from '../../services/quotes-service';
import './component-quote.scss';

export function Quote() {
    const quote = QuotesService.getQuote();

    return (
        <Container className="quote">
            <Grid centered
                style={{ height: "100vh" }}
                verticalAlign="middle">
                <Grid.Row>
                    <Grid.Column>
                        <Header as="h1">
                            {quote}
                        </Header>
                        <div className="powered-by">
                            <span className="powered-by-label">
                                Powered by
                            </span>
                            <Image
                                src="logo.png"
                                size="tiny" />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}