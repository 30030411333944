import React from 'react';
import { Serie, ResponsiveLine } from '@nivo/line';
import './component-line-chart.scss';

const mockData: Serie[] = [
	{
		id: 'japan',
		color: 'hsl(334, 70%, 50%)',
		data: [
			{
				x: 'plane',
				y: 296
			},
			{
				x: 'helicopter',
				y: 198
			},
			{
				x: 'boat',
				y: 268
			},
			{
				x: 'train',
				y: 158
			},
			{
				x: 'subway',
				y: 251
			},
			{
				x: 'bus',
				y: 131
			},
			{
				x: 'car',
				y: 184
			},
			{
				x: 'moto',
				y: 41
			},
			{
				x: 'bicycle',
				y: 175
			},
			{
				x: 'horse',
				y: 128
			},
			{
				x: 'skateboard',
				y: 91
			},
			{
				x: 'others',
				y: 195
			}
		]
	}
];

export function LineChart() {
	return <MyResponsiveLine data={mockData} />;
}

interface IMyResponsiveLineProps {
	data: Serie[];
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveLine = (props: IMyResponsiveLineProps) => (
	<ResponsiveLine
		data={props.data}
		curve="monotoneX"
		margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
		xScale={{ type: 'point' }}
		yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
		axisTop={null}
		axisRight={null}
		axisBottom={null}
		axisLeft={null}
		colors={'#6FCF97'}
		pointSize={3}
		pointColor={{ theme: 'background' }}
		pointBorderWidth={2}
		pointBorderColor={{ from: 'serieColor' }}
		pointLabel="y"
	/>
);
