import {
	IProjectGoal,
	IPublicUserProfile,
	RecurrenceType,
	GoalType,
	GoalEventType,
	IDayStats
} from '../data/interfaces';
import { MockService } from './mock-service';
import {
	isLastHour,
	isToday,
	isSameDay,
	substractDaysFromToday,
	isTodayOrFutureFromDate,
	isDateOnSameDateOrLater
} from '../utils/time-utils';
import _ from 'lodash';
import firebase from 'firebase';
import { UserService } from './user-service';
import moment from 'moment';

export class GoalsService {
	public static userProfile: IPublicUserProfile;
	public static updated: boolean = false;

	static getMyProfile(): IPublicUserProfile {
		const profile = localStorage.getItem('myProfile');
		if (profile) {
			GoalsService.userProfile = JSON.parse(profile) as IPublicUserProfile;
		} else {
			GoalsService.resetMockData();
		}
		return GoalsService.userProfile;
	}

	static isGoalComplete(goal: IProjectGoal): boolean {
		if (goal.completed) return true;
		if (!goal.history || goal.history?.length <= 0) return false;
		const lastCompletion: Date = goal.history[goal.history.length - 1].date;
		switch (goal.due?.recurrance) {
			case RecurrenceType.Hourly:
				if (isLastHour(lastCompletion)) return true;
				break;

			//TODO: Add logic to support different recurrance variations
			case RecurrenceType.Daily:
			case RecurrenceType.Weekdays:
			case RecurrenceType.Weekends:
			case RecurrenceType.Weekly:
			case RecurrenceType.Monthly:
			case RecurrenceType.Yearly:
				if (isToday(lastCompletion)) return true;
				break;
		}
		return false;
	}

	static getGoal(id: string, startGoal?: IProjectGoal): IProjectGoal | undefined {
		if (startGoal) {
			if (startGoal.id === id) return startGoal;
			for (const how of startGoal.hows) {
				const found = GoalsService.getGoal(id, how);
				if (found) return found;
			}
		} else {
			const userProfile = GoalsService.userProfile ?? GoalsService.getMyProfile();
			for (const goal of userProfile.projects[0].goals) {
				const found = GoalsService.getGoal(id, goal);
				if (found) return found;
			}
		}
		return undefined;
	}

	static addSubGoalTo(id: string, subgoal: IProjectGoal): void {
		const goal = GoalsService.getGoal(id);
		goal?.hows.push(subgoal);
		GoalsService.updateLocalStorage();
	}

	static addPurposeToGoal(goalId: string, name: string): void {
		const goal = GoalsService.getGoal(goalId);
		if (goal) {
			const goalParent = GoalsService.getParentGoal(goal);
			const newGoal: IProjectGoal = {
				id: GoalsService.newId(),
				goal: {
					id: GoalsService.newId(),
					type: GoalType.Objective,
					name: name
				},
				startValue: undefined,
				goalValue: undefined,
				goalDate: undefined,
				created: new Date(),
				hows: [goal],
				completed: false,
				archived: false,
				history: []
			};
			if (goalParent) {
				const index = _.findIndex(goalParent.hows, (g) => {
					return g.id === goalId;
				});
				if (index >= 0) {
					goalParent.hows[index] = newGoal;
				}
			} else {
				const index = _.findIndex(GoalsService.userProfile.projects[0].goals, (g) => {
					return g.id === goalId;
				});
				if (index >= 0) {
					GoalsService.userProfile.projects[0].goals[index] = newGoal;
				}
			}
			GoalsService.updateLocalStorage();
		}
	}

	static addTopLevelGoal(name: string, type: GoalType): void {
		GoalsService.addSimpleNewSubGoalTo(undefined, name, type);
	}

	static createGeneralizedGoal(
		parentGoal: IProjectGoal,
		goalA: IProjectGoal,
		goalB: IProjectGoal,
		name: string,
		type: GoalType
	) {
		GoalsService.addSimpleNewSubGoalTo(parentGoal, name, type, undefined, undefined, undefined, [goalA, goalB]);
	}

	static addSimpleNewSubGoalTo(
		parentGoal: IProjectGoal | undefined,
		name: string,
		type: GoalType,
		goalDate?: Date,
		startValue?: number,
		goalValue?: number,
		subgoalHows?: IProjectGoal[]
	): IProjectGoal | undefined {
		let goal: IProjectGoal | undefined;
		if (parentGoal) {
			goal = GoalsService.getGoal(parentGoal.id);
		}
		const subgoal: IProjectGoal = {
			id: GoalsService.newId(),
			goal: {
				id: GoalsService.newId(),
				type: type,
				name: name
			},
			startValue: startValue,
			goalValue: goalValue,
			goalDate: goalDate,
			created: new Date(),
			hows: subgoalHows ?? [],
			completed: false,
			archived: false,
			history: []
		};
		if (goal) {
			goal.hows.push(subgoal);
		} else {
			const currentUserGoals =
				GoalsService.userProfile.projects[0].goals ?? GoalsService.getMyProfile().projects[0].goals;
			const visibleGoals = currentUserGoals.filter((g) => GoalsService.shouldGoalBeDisplayed(g, new Date()));
			if (visibleGoals.length < 3) {
				currentUserGoals.push(subgoal);
			}
		}
		GoalsService.updateLocalStorage();
		return subgoal;
	}

	static newId(length = 10): string {
		let id = '';
		const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < length; i++) id += possible.charAt(Math.floor(Math.random() * possible.length));
		return id;
	}

	static updateGoal(goalId: string, name: string, type: GoalType) {
		const goal = GoalsService.getGoal(goalId);
		if (goal) {
			goal.goal.name = name;
			goal.goal.type = type;
			GoalsService.updateLocalStorage();
		}
	}

	static updateLocalStorage() {
		localStorage.setItem('myProfile', JSON.stringify(GoalsService.userProfile));
		GoalsService.updated = true;
	}

	static resetMockData() {
		localStorage.setItem('myProfile', JSON.stringify(MockService.mockProfile));
		GoalsService.userProfile = MockService.mockProfile;
	}

	static getActionableTasksFromGoal(id: string, startGoal?: IProjectGoal): IProjectGoal[] {
		let actionableGoals: IProjectGoal[] = [];
		if (!startGoal) {
			actionableGoals = GoalsService.getActionableTasksFromGoal(id, GoalsService.getGoal(id));
		} else {
			if (startGoal.goal.type !== GoalType.Objective) {
				actionableGoals.push(startGoal);
			}
			startGoal.hows.forEach((g) => {
				actionableGoals = actionableGoals.concat(GoalsService.getActionableTasksFromGoal(id, g));
			});
		}
		return actionableGoals;
	}

	static wasCompletedToday(goal: IProjectGoal) {
		return GoalsService.wasCompletedOnDate(goal, new Date());
	}

	static wasCompletedOnDate(goal: IProjectGoal, date: Date) {
		if (goal.history && goal.history.length > 0) {
			const eventFromDay = goal.history.find((h) => {
				return (
					new Date(h.date).getDate() === date.getDate() &&
					new Date(h.date).getMonth() === date.getMonth() &&
					new Date(h.date).getFullYear() === date.getFullYear()
				);
			});
			return eventFromDay && eventFromDay !== null && eventFromDay !== undefined;
		}
		return false;
	}

	static statsForDay(date: Date, startGoal?: IProjectGoal): IDayStats {
		const stats: IDayStats = {
			day: date,
			completedBlockers: [],
			completedKeyResults: [],
			pendingBlockers: [],
			pendingKeyResults: []
		};
		const userProfile = GoalsService.getMyProfile();
		if (startGoal === null || startGoal === undefined) {
			userProfile.projects[0].goals.forEach((g) => {
				const gStats = GoalsService.statsForDay(date, g);
				stats.completedBlockers.push(...gStats.completedBlockers);
				stats.completedKeyResults.push(...gStats.completedKeyResults);
				stats.pendingBlockers.push(...gStats.pendingBlockers);
				stats.pendingKeyResults.push(...gStats.pendingKeyResults);
			});
		} else {
			if (startGoal.history?.find((h) => isSameDay(h.date, date))) {
				if (startGoal.goal.type === GoalType.GoodHabit) {
					stats.completedKeyResults.push(startGoal);
				} else if (startGoal.goal.type === GoalType.BadHabit) {
					stats.completedBlockers.push(startGoal);
				}
			} else {
				if (startGoal.goal.type === GoalType.GoodHabit) {
					stats.pendingKeyResults.push(startGoal);
				} else if (startGoal.goal.type === GoalType.BadHabit) {
					stats.pendingBlockers.push(startGoal);
				}
			}
			startGoal.hows.forEach((h) => {
				const howStats = GoalsService.statsForDay(date, h);
				stats.completedBlockers.push(...howStats.completedBlockers);
				stats.completedKeyResults.push(...howStats.completedKeyResults);
				stats.pendingBlockers.push(...howStats.pendingBlockers);
				stats.pendingKeyResults.push(...howStats.pendingKeyResults);
			});
		}
		return stats;
	}

	static touchGoal(id: string, date?: Date, newValue?: number) {
		const goal = GoalsService.getGoal(id);
		let touchDate = date ? date : new Date();
		const wasGoalAlreadyCompletedOnDate = goal ? GoalsService.wasCompletedOnDate(goal, touchDate) : false;
		if (goal) {
			let newHistoryId = id;
			if (goal.history && goal.history.length > 0) {
				const lastHistoryEvent = goal.history[goal.history.length - 1];
				const newHistoryEventId = Number.parseInt(lastHistoryEvent.id.split('-')[4]) + 1;
				newHistoryId = `${id}-${newHistoryEventId.toString().padStart(8, '0')}`;
			} else {
				newHistoryId = `${id}-00000001`;
			}
			if (date) {
				touchDate = date;
			}
			switch (goal.goal.type) {
				case GoalType.BadHabit:
				case GoalType.GoodHabit:
					if (!wasGoalAlreadyCompletedOnDate) {
						goal?.history?.push({
							id: newHistoryId,
							type: GoalEventType.SingleEvent,
							date: touchDate
						});
					} else {
						if (goal.history && goal.history.length > 0) {
							_.remove(goal.history, (h) => {
								return (
									new Date(h.date).getDate() === touchDate.getDate() &&
									new Date(h.date).getMonth() === touchDate.getMonth() &&
									new Date(h.date).getFullYear() === touchDate.getFullYear()
								);
							});
						}
					}
					break;
				case GoalType.Objective:
				case GoalType.OneTimeTask:
					if (!wasGoalAlreadyCompletedOnDate) {
						goal.completed = true;
						goal.history?.push({
							id: newHistoryId,
							type: GoalEventType.SingleEvent,
							date: touchDate
						});
					} else {
						goal.completed = false;
						if (goal.history && goal.history.length > 0) {
							_.remove(goal.history, (h) => {
								return (
									new Date(h.date).getDate() === touchDate.getDate() &&
									new Date(h.date).getMonth() === touchDate.getMonth() &&
									new Date(h.date).getFullYear() === touchDate.getFullYear()
								);
							});
						}
					}
					break;
				case GoalType.Target:
					goal.history?.push({
						id: newHistoryId,
						type: GoalEventType.SingleEvent,
						date: touchDate,
						value: newValue
					});
					break;
			}
		}
		GoalsService.updateLocalStorage();
	}

	static updateOrderOfSubGoals(parentGoalId: string, newSubGoals: IProjectGoal[]) {
		const parentGoal = GoalsService.getGoal(parentGoalId);
		if (parentGoal) {
			parentGoal.hows = newSubGoals;
			GoalsService.updateLocalStorage();
		}
	}

	static updateOrderOfTopGoals(newGoals: IProjectGoal[]) {
		const profile = GoalsService.userProfile ?? GoalsService.getMyProfile();
		profile.projects[0].goals = newGoals;
		GoalsService.updateLocalStorage();
	}

	static isTopGoal(id: string): boolean {
		return GoalsService.getMyProfile().projects[0].goals.some((g) => g.id === id);
	}

	static parentGoal(id: string, currentGoal?: IProjectGoal): IProjectGoal | undefined {
		if (currentGoal) {
			if (currentGoal.hows.some((g) => g.id === id)) {
				return currentGoal;
			} else {
				let parentGoalFound: IProjectGoal | undefined = undefined;
				currentGoal.hows.some((h) => {
					const pGoal = GoalsService.parentGoal(id, h);
					if (pGoal !== undefined) {
						parentGoalFound = pGoal;
						return true;
					}
					return false;
				});
				return parentGoalFound;
			}
		} else {
			const userProfile = GoalsService.getMyProfile();
			if (GoalsService.isTopGoal(id)) {
				return undefined;
			}
			let parentGoalFound: IProjectGoal | undefined = undefined;
			userProfile.projects[0].goals.forEach((g) => {
				const pGoal = GoalsService.parentGoal(id, g);
				if (pGoal !== undefined) {
					parentGoalFound = pGoal;
					return true;
				}
				return false;
			});
			return parentGoalFound;
		}
	}

	static getGoalBreadcrumb(
		goal: IProjectGoal | undefined,
		currentGoal?: IProjectGoal,
		breadcrumbTrail: IProjectGoal[] = []
	): IProjectGoal[] | undefined {
		let breadcrumb: IProjectGoal[] | undefined = undefined;
		if (goal) {
			if (currentGoal === undefined) {
				const userGoals = GoalsService.getMyProfile().projects[0].goals;
				userGoals.some((g) => {
					breadcrumb = GoalsService.getGoalBreadcrumb(goal, g);
					return breadcrumb !== undefined;
				});
			} else {
				if (currentGoal.id === goal.id) {
					breadcrumb = breadcrumbTrail;
				} else {
					currentGoal.hows.some((g) => {
						const tentativeBreadcrumb = GoalsService.getGoalBreadcrumb(
							goal,
							g,
							breadcrumbTrail.concat([currentGoal])
						);
						if (tentativeBreadcrumb !== undefined) {
							breadcrumb = tentativeBreadcrumb;
						}
						return breadcrumb !== undefined;
					});
				}
			}
		}
		return breadcrumb;
	}

	static getDayProgress(date: Date): number {
		const userGoals = GoalsService.getMyProfile().projects[0].goals;
		let progress = 0;
		const topGoalCount = userGoals.length;
		if (topGoalCount > 0) {
			userGoals.forEach((g) => {
				progress += GoalsService.getGoalProgress(g, date);
			});
			progress = progress / topGoalCount;
		}
		return progress;
	}

	static getGoalProgress(goal: IProjectGoal, date?: Date): number {
		let progress = GoalsService.getSingleGoalProgress(goal, date);
		const howCount = goal.hows.length;
		if (howCount > 0) {
			let subGoalsProgress = 0;
			goal.hows.forEach((g) => {
				let subGoalProg = GoalsService.getGoalProgress(g, date);
				if (g.goal.type === GoalType.BadHabit && subGoalProg === 0) {
					subGoalProg = 1;
				}
				subGoalsProgress += subGoalProg;
			});
			const factor = goal.goal.type === GoalType.Objective ? 0 : 0.5;
			progress = progress * factor + (subGoalsProgress / howCount) * (1 - factor);
		}
		progress = goal.goal.type === GoalType.BadHabit ? -1 * progress : progress;
		return progress;
	}

	static getSingleGoalProgress(goal: IProjectGoal, date?: Date): number {
		if (date) {
			return GoalsService.wasCompletedOnDate(goal, date) ? 1 : 0;
		} else {
			if (goal.goal.type !== GoalType.OneTimeTask) {
				const TIMEFRAME = 7;
				let goalCount = 0;
				if (goal.history && goal.history?.length > 0) {
					_.range(TIMEFRAME).forEach((n) => {
						const nDate = substractDaysFromToday(n);
						goalCount += GoalsService.wasCompletedOnDate(goal, nDate) ? 1 : 0;
					});
				}
				return goalCount / TIMEFRAME;
			} else {
				return GoalsService.isGoalComplete(goal) ? 1 : 0;
			}
		}
	}

	static getParentGoal(goal?: IProjectGoal): IProjectGoal | undefined {
		const breadcrumb = GoalsService.getGoalBreadcrumb(goal);
		if (breadcrumb && breadcrumb.length > 0) {
			return breadcrumb[breadcrumb.length - 1];
		}
		return undefined;
	}

	static archiveGoal(g: IProjectGoal, updateStorage = true) {
		const goal = GoalsService.getGoal(g.id);
		if (goal) {
			goal.archived = true;
			goal.archivedDate = new Date();
			goal.hows.forEach((h) => GoalsService.archiveGoal(h, false));
		}
		if (updateStorage) {
			GoalsService.updateLocalStorage();
		}
	}

	static shouldGoalBeDisplayed(goal: IProjectGoal, date: Date, hidden?: IProjectGoal[]): boolean {
		let shouldBeDisplayed = true;
		if (goal.completed && goal.history && goal.history.length > 0) {
			const completedDate = goal.history[goal.history.length - 1].date;
			shouldBeDisplayed = isTodayOrFutureFromDate(completedDate, date);
		}
		if (!isDateOnSameDateOrLater(goal.created, date)) {
			shouldBeDisplayed = false;
		}
		if (goal.archived) {
			shouldBeDisplayed = false;
		}
		/*
		if (hidden && _.some(hidden, (g) => g.id === goal.id)) {
			shouldBeDisplayed = false;
		}
		*/
		return shouldBeDisplayed;
	}

	static getGoalIndex(goal: IProjectGoal, date?: Date, hidden?: IProjectGoal[]): number {
		let outIndex = 0;
		let counter = 0;
		const activeDate = date ?? new Date();
		const enumerateTree = (currentNode?: IProjectGoal) => {
			if (currentNode) {
				if (currentNode.id === goal.id) {
					outIndex = counter;
				} else {
					counter++;
					currentNode.hows
						.filter((g) => GoalsService.shouldGoalBeDisplayed(g, activeDate, hidden))
						.forEach((h) => {
							enumerateTree(h);
						});
				}
			} else {
				const goals = (GoalsService.userProfile && GoalsService.getMyProfile()).projects[0].goals;
				goals
					.filter((g) => GoalsService.shouldGoalBeDisplayed(g, activeDate, hidden))
					.forEach((g) => enumerateTree(g));
			}
		};
		enumerateTree();
		return outIndex;
	}

	static getGoalFromIndex(index: number, date?: Date): IProjectGoal | undefined {
		let node: IProjectGoal | undefined = undefined;
		let counter = 0;
		const activeDate = date ?? new Date();
		const enumerateTree = (currentNode?: IProjectGoal) => {
			if (currentNode) {
				if (counter === index) {
					node = currentNode;
				} else {
					counter++;
					currentNode.hows
						.filter((g) => GoalsService.shouldGoalBeDisplayed(g, activeDate))
						.forEach((h) => {
							node === undefined && enumerateTree(h);
						});
				}
			} else {
				const goals = (GoalsService.userProfile && GoalsService.getMyProfile()).projects[0].goals;
				goals
					.filter((g) => GoalsService.shouldGoalBeDisplayed(g, activeDate))
					.forEach((g) => node === undefined && enumerateTree(g));
			}
		};
		enumerateTree();
		return node;
	}

	static getGoalsUnder(goal: IProjectGoal, types: GoalType[], date?: Date): IProjectGoal[] {
		const habits: IProjectGoal[] = [];
		const goalInventory = (currentGoal: IProjectGoal, activeDate: Date) => {
			if (_.some(types, (t) => t === currentGoal.goal.type)) {
				habits.push(currentGoal);
			} else {
				currentGoal.hows
					.filter((g) => GoalsService.shouldGoalBeDisplayed(g, activeDate))
					.forEach((h) => {
						goalInventory(h, activeDate);
					});
			}
		};
		goalInventory(goal, date ?? new Date());
		return habits;
	}

	static async saveToStorage() {
		const currentUser = await UserService.getCurrentUser();
		if (currentUser) {
			const storageRef = firebase.storage().ref();
			const userProfileJSONFile = storageRef.child(`${currentUser.id}/profile.json`);
			const userProfileJSONFileBackup = storageRef.child(
				`${currentUser.id}/profile-${moment(new Date()).format('YYYYMMDDHHMMSS')}.json`
			);
			const profileJSON = JSON.stringify(GoalsService.getMyProfile());
			const blob = new Blob([profileJSON], { type: 'application/json' });
			userProfileJSONFile.put(blob).then(function (snapshot) {
				GoalsService.updated = false;
			});
			userProfileJSONFileBackup.put(blob).then(function (snapshot) {
				GoalsService.updated = false;
			});
		}
	}

	static async fetchProfileFromStorage() {
		const currentUser = await UserService.getCurrentUser();
		if (currentUser) {
			const storageRef = firebase.storage().ref();
			console.log(`Loading ${currentUser.id}/profile.json`);
			storageRef
				.child(`${currentUser.id}/profile.json`)
				.getDownloadURL()
				.then(function (url) {
					console.log(url);
					fetch(url)
						.then((res) => res.json())
						.then((out) => {
							if (out && out.id) {
								localStorage.setItem('myProfile', JSON.stringify(out));
								window.location.reload();
							} else {
								alert(
									'No valid data was found on the database.\nMake sure you save your profile at least once.'
								);
							}
						})
						.catch((err) => {
							alert('Error loading profile.\nMake sure you save your profile at least once.');
							console.error(`ERROR fetching file from ${url}\n:${err}`);
						});
				})
				.catch(function (error) {
					alert('Error loading profile.\nMake sure you save your profile at least once.');
					console.error('ERROR loading profile:', error);
				});
		}
	}

	static updatesHaveBeenMade() {
		return GoalsService.updated;
	}

	static getGoalTypeString(goal: IProjectGoal) {
		switch (goal.goal.type) {
			case GoalType.BadHabit:
				return 'Bad habit';
			case GoalType.GoodHabit:
				return 'Good habit';
			case GoalType.Objective:
				return 'Objective';
			case GoalType.OneTimeTask:
				return 'One time task';
			case GoalType.Target:
				return 'Target';
		}
	}
}
