import React from 'react';
import { Container } from 'semantic-ui-react';
import './view-home.scss';
import { Quote } from '../../components/component-quote';

export function Home() {
    return (
        <Container>
            <Quote />
        </Container>
    );
}