import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Icon, Header, Grid, Segment, Button, Confirm } from 'semantic-ui-react';
import { GoalsService } from '../../services/goals-service';
import './view-goal.scss';
import { ProgressRing } from '../../components/component-progress-ring';
import { LineChart } from '../../components/component-line-chart';
import { GoalTree } from '../../components/component-goal-tree';
import { GoalType } from '../../data/interfaces';
import { ModalNewGoal } from '../../components/component-modal-new-goal';

export function Goal(props: any) {
	const [updateUI, setUpdateUI] = useState(1);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const history = useHistory();
	const { id } = props.match.params;
	const goal = GoalsService.getGoal(id);
	const goalBreadcrumb = GoalsService.getGoalBreadcrumb(goal);
	const goalProgressToday = goal ? GoalsService.getGoalProgress(goal, new Date()) : 0;
	const goalProgressOverall = goal ? GoalsService.getGoalProgress(goal) : 0;
	const archiveGoal = () => {
		goal && GoalsService.archiveGoal(goal);
		setConfirmDelete(false);
		history.push(`/dashboard`);
	};

	return (
		<Container className="goal-details">
			<Button as={Link} labelPosition="left" icon="arrow left" to="/dashboard">
				<Icon name="arrow left" />
				Back to dashboard
			</Button>
			<Header as="h1">{goal?.goal.name}</Header>
			<Header as="h4">
				{goal && GoalsService.getGoalTypeString(goal)}
				{goalBreadcrumb && goalBreadcrumb.length > 0 && (
					<>
						{' '}
						to <Link to={`/goal/${goalBreadcrumb[0].id}`}>{goalBreadcrumb[0].goal.name}</Link>
					</>
				)}
			</Header>
			<Grid stackable columns="2">
				<Grid.Row>
					<Grid.Column>
						<Grid stackable={false}>
							<Grid.Row columns="2">
								<Grid.Column>
									<Segment className="metric">
										<Grid>
											<Grid.Row columns="2" centered>
												<Grid.Column width={7} style={{ textAlign: 'center' }}>
													<ProgressRing
														radius={35}
														stroke={6}
														progress={
															goalProgressToday < 0
																? -1 * goalProgressToday
																: goalProgressToday
														}
														flip={goalProgressToday < 0 ? false : true}
														color={goalProgressToday < 0 ? '#EB5757' : '#6FCF97'}
													/>
												</Grid.Column>
												<Grid.Column width={9}>
													<div className="metric-value">
														{goalProgressToday < 0
															? Math.round(-100 * goalProgressToday)
															: Math.round(100 * goalProgressToday)}
														%
													</div>
													<div className="metric-label">Today</div>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Segment>
								</Grid.Column>
								<Grid.Column>
									<Segment className="metric">
										<Grid>
											<Grid.Row columns="2" centered>
												<Grid.Column width={7} style={{ textAlign: 'center' }}>
													<ProgressRing
														radius={35}
														stroke={6}
														progress={
															goalProgressOverall < 0
																? -1 * goalProgressOverall
																: goalProgressOverall
														}
														flip={goalProgressOverall < 0 ? false : true}
														color={goalProgressOverall < 0 ? '#EB5757' : '#6FCF97'}
													/>
												</Grid.Column>
												<Grid.Column width={9}>
													<div className="metric-value">
														{goalProgressOverall < 0
															? Math.round(-100 * goalProgressOverall)
															: Math.round(100 * goalProgressOverall)}
														%
													</div>
													<div className="metric-label">Overall</div>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Segment>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
					<Grid.Column width="8">
						<Segment className="metric">
							<LineChart></LineChart>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<Button
				style={{ marginTop: '2rem' }}
				onClick={() => setConfirmDelete(true)}
				icon="archive"
				labelPosition="left"
			>
				<Icon name="archive" />
				Archive goal
			</Button>
			<Confirm
				content="Are you sure you want to archive this goal?"
				open={confirmDelete}
				onCancel={() => setConfirmDelete(false)}
				onConfirm={archiveGoal}
			/>

			<Header as="h3">Sub-goals</Header>
			{goal && (
				<GoalTree
					goals={goal?.hows}
					isTopLevelTree={false}
					date={new Date()}
					onUpdate={() => {
						setUpdateUI(updateUI + 1);
					}}
				/>
			)}
			{goal &&
				goal?.hows.filter((h) => GoalsService.shouldGoalBeDisplayed(h, new Date())).length < 3 &&
				goal.goal.type === GoalType.Objective && (
					<ModalNewGoal
						parentGoal={goal}
						onUpdate={() => {
							setUpdateUI(updateUI + 1);
						}}
					/>
				)}
		</Container>
	);
}
