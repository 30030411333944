import React, { useState } from 'react';
import { Container, Header, Icon, Modal, Button, Grid, Segment, Input, Dropdown } from 'semantic-ui-react';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DragStart,
	Droppable,
	DroppableProvided,
	DroppableStateSnapshot,
	DropResult,
	ResponderProvided
} from 'react-beautiful-dnd';

import './view-checkpoint.scss';

import { GoalsService } from '../../services/goals-service';
import { substractDaysFromToday } from '../../utils/time-utils';
import { GoalType, IProjectGoal } from '../../data/interfaces';
import { DateCarousel } from '../../components/component-date-carousel';
import { GoalTree } from '../../components/component-goal-tree';

function getLatestKeyResultStats(n: number) {
	const stats: number[] = [];
	for (let i = n - 1; i >= 0; i--) {
		const d = substractDaysFromToday(i);
		const dayStats = GoalsService.statsForDay(d);
		stats.push(
			dayStats.completedKeyResults.length /
				(dayStats.completedKeyResults.length + dayStats.pendingKeyResults.length)
		);
	}
	return stats;
}

function getLatestBlockerStats(n: number) {
	const stats: number[] = [];
	for (let i = n - 1; i >= 0; i--) {
		const d = substractDaysFromToday(i);
		const dayStats = GoalsService.statsForDay(d);
		stats.push(
			dayStats.completedBlockers.length / (dayStats.completedBlockers.length + dayStats.pendingBlockers.length)
		);
	}
	return stats;
}

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const insert = (list: any[], item: any, insertIndex: number): any[] => {
	const result = Array.from(list);
	result.splice(insertIndex, 0, item);
	return result;
};

const remove = (list: any[], deleteIndex: number): any[] => {
	const result = Array.from(list);
	result.splice(deleteIndex, 1);
	return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	background: isDragging ? 'lightgreen' : '#ccc',

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
	background: isDraggingOver ? 'lightblue' : 'lightgrey',
	padding: grid,
	width: 250
});

interface IGoalCombinationAction {
	isCombination: boolean;
	from?: IProjectGoal;
	to?: IProjectGoal;
	sourceDroppableId?: string;
	sourceIndex?: number;
	destinationDroppableId?: string;
	destinationIndex?: number;
}

interface INewQuickGoal {
	name?: string;
	type?: GoalType;
}

export function Checkpoint() {
	const [activeDate, setActiveDate] = useState(new Date());
	const [update, setUpdate] = useState(0);
	const [userProfile, setUserProfile] = useState(GoalsService.getMyProfile());
	const [disabledDroppers, setDisabledDroppers] = useState([false, false, false]);
	const [confirmGoalCombine, setConfirmGoalCombine] = useState({ isCombination: false } as IGoalCombinationAction);
	const [newGoal, setNewGoal] = useState({ name: '', type: GoalType.Objective } as INewQuickGoal);
	const keyResultStats = getLatestKeyResultStats(4);
	const blockerStats = getLatestBlockerStats(4);

	const onDragEnd = (result: DropResult, provided?: ResponderProvided) => {
		if (result.combine) {
			const draggedGoal = GoalsService.getGoal(result.draggableId);
			const droppedIntoGoal = GoalsService.getGoal(result.combine.draggableId);
			setConfirmGoalCombine({
				isCombination: true,
				from: draggedGoal,
				to: droppedIntoGoal,
				sourceDroppableId: GoalsService.parentGoal(draggedGoal?.id ?? '')?.id,
				sourceIndex: result.source.index,
				destinationDroppableId: GoalsService.parentGoal(droppedIntoGoal?.id ?? '')?.id,
				destinationIndex: GoalsService.parentGoal(droppedIntoGoal?.id ?? '')?.hows.findIndex(
					(h) => h.id === droppedIntoGoal?.id
				)
			});
		} else if (!result.destination) {
			return;
		} else {
			const destinationParentGoal = GoalsService.getGoal(result.destination.droppableId);
			if (destinationParentGoal) {
				if (result.source.droppableId === result.destination.droppableId) {
					const newHows = reorder(
						destinationParentGoal?.hows ?? [],
						result.source.index,
						result.destination.index
					);
					GoalsService.updateOrderOfSubGoals(result.destination.droppableId, newHows);
				} else {
					const sourceParentGoal = GoalsService.getGoal(result.source.droppableId);
					const draggedGoal = GoalsService.getGoal(result.draggableId);
					const newHowsDestination = insert(
						destinationParentGoal?.hows ?? [],
						draggedGoal,
						result.destination.index
					);
					const newHowsSource = remove(sourceParentGoal?.hows ?? [], result.source.index);
					GoalsService.updateOrderOfSubGoals(result.destination.droppableId, newHowsDestination);
					GoalsService.updateOrderOfSubGoals(result.source.droppableId, newHowsSource);
				}
				setUserProfile(GoalsService.getMyProfile());
			}
		}
	};

	const onDragStart = (initial: DragStart, provided: ResponderProvided): void => {
		const updatedDisabledDrops: boolean[] = userProfile.projects[0].goals.map((g) => {
			const parentGoal = GoalsService.parentGoal(initial.draggableId);
			return g.hows.length >= 3 && parentGoal?.id !== g.id;
		});
		setDisabledDroppers(updatedDisabledDrops);
	};

	const createNewGoal = (
		parentGoal: IProjectGoal | undefined,
		goalA: IProjectGoal | undefined,
		goalB: IProjectGoal | undefined,
		name: string | undefined,
		type: GoalType | undefined
	) => {
		if (parentGoal && goalA && goalB && name && type !== undefined) {
			GoalsService.createGeneralizedGoal(parentGoal, goalA, goalB, name, type);
		}
		setNewGoal({ name: '', type: GoalType.Objective });
		setConfirmGoalCombine({ isCombination: false });
	};

	const addChild = (goalParent: IProjectGoal | undefined, goalChild: IProjectGoal | undefined) => {
		if (goalParent && goalChild) {
			GoalsService.addSubGoalTo(goalParent.id, goalChild);
			if (confirmGoalCombine.sourceIndex && confirmGoalCombine.sourceDroppableId) {
				const parentGoal = GoalsService.getGoal(confirmGoalCombine.sourceDroppableId);
				const newHows = parentGoal?.hows.filter((g) => g.id !== goalChild.id);
				if (newHows) {
					GoalsService.updateOrderOfSubGoals(confirmGoalCombine.sourceDroppableId, newHows);
				}
			}
		}
		setNewGoal({ name: '', type: GoalType.Objective });
		setConfirmGoalCombine({ isCombination: false });
	};

	const generalizeSelectedGoals = () => {
		const parentGoalSource = GoalsService.getGoal(confirmGoalCombine.sourceDroppableId ?? '');
		const parentGoalDestination = GoalsService.getGoal(confirmGoalCombine.sourceDroppableId ?? '');
		if (
			confirmGoalCombine.sourceIndex !== undefined &&
			confirmGoalCombine.destinationIndex !== undefined &&
			confirmGoalCombine.sourceIndex > confirmGoalCombine.destinationIndex
		) {
			const newSourceHows = remove(parentGoalSource?.hows ?? [], confirmGoalCombine.sourceIndex ?? -1);
			const newDestinationHows = remove(
				confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId
					? newSourceHows
					: parentGoalDestination?.hows ?? [],
				confirmGoalCombine.destinationIndex ?? -1
			);
			if (confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId) {
				GoalsService.updateOrderOfSubGoals(parentGoalSource?.id ?? '', newDestinationHows);
			} else {
				GoalsService.updateOrderOfSubGoals(parentGoalDestination?.id ?? '', newDestinationHows);
			}
		} else {
			const newDestinationHows = remove(
				parentGoalDestination?.hows ?? [],
				confirmGoalCombine.destinationIndex ?? -1
			);
			const newSourceHows = remove(
				confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId
					? newDestinationHows
					: parentGoalSource?.hows ?? [],
				confirmGoalCombine.sourceIndex ?? -1
			);
			if (confirmGoalCombine.sourceDroppableId === confirmGoalCombine.destinationDroppableId) {
				GoalsService.updateOrderOfSubGoals(parentGoalDestination?.id ?? '', newSourceHows);
			} else {
				GoalsService.updateOrderOfSubGoals(parentGoalSource?.id ?? '', newSourceHows);
			}
		}
		createNewGoal(
			GoalsService.getGoal(parentGoalDestination?.id ?? ''),
			confirmGoalCombine.to,
			confirmGoalCombine.from,
			newGoal.name,
			newGoal.type
		);
	};

	const goalTypes = [
		{ key: 'objective', text: 'Objective', value: GoalType.Objective },
		{ key: 'good-habit', text: 'Good habit', value: GoalType.GoodHabit },
		{ key: 'bad-habit', text: 'Bad habit', value: GoalType.BadHabit },
		{ key: 'one-time', text: 'One-time task', value: GoalType.OneTimeTask },
		{ key: 'target', text: 'Target', value: GoalType.Target }
	];

	return (
		<Container className="checkpoint">
			<Button
				onClick={() => {
					GoalsService.resetMockData();
					window.location.reload();
				}}
			>
				Reset
			</Button>
			<Modal
				className={'confirm-goal-combine'}
				onClose={() => setConfirmGoalCombine({ isCombination: false })}
				onOpen={() => setConfirmGoalCombine({ isCombination: false })}
				open={confirmGoalCombine.isCombination}
			>
				<Modal.Header>Combine goals</Modal.Header>
				<Modal.Content>
					<Grid columns={2} stackable>
						<Grid.Row verticalAlign="middle">
							<Grid.Column>
								<Segment>
									<Header>As parent and child</Header>
									<div className="tree-view">
										<div className="tree-view-parent">{confirmGoalCombine.to?.goal.name}</div>
										<div className="tree-view-child first-child">
											{confirmGoalCombine.from?.goal.name}
										</div>
									</div>
									<Button
										onClick={() => addChild(confirmGoalCombine.to, confirmGoalCombine.from)}
										primary
									>
										Accept
									</Button>
								</Segment>
							</Grid.Column>
							<Grid.Column>
								<Segment>
									<Header>New parent</Header>
									<div className="tree-view">
										<div className="tree-view-parent new-parent">
											<Input
												action={
													<Dropdown
														button
														basic
														floating
														options={goalTypes}
														onChange={(event, value) => {
															setNewGoal({
																name: newGoal.name,
																type: value.value as GoalType
															});
														}}
														value={newGoal.type}
													/>
												}
												value={newGoal.name}
												onChange={(event) =>
													setNewGoal({ name: event.target.value, type: newGoal.type })
												}
												style={{ width: '100%' }}
												actionPosition="left"
												placeholder="New goal..."
											/>
										</div>
										<div className="tree-view-child first-child">
											{confirmGoalCombine.to?.goal.name}
										</div>
										<div className="tree-view-child second-child">
											{confirmGoalCombine.from?.goal.name}
										</div>
									</div>
									<Button
										primary
										onClick={() => {
											generalizeSelectedGoals();
										}}
										disabled={newGoal.name === undefined || newGoal.name.trim().length <= 0}
									>
										Create
									</Button>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
			</Modal>
			<Header as="h1">Checkpoint</Header>
			<DateCarousel
				days={4}
				activeDate={activeDate}
				dateChanged={setActiveDate}
				ring1Color={'#21ba45'}
				ring2Color={'#db2828'}
				ring1Percentages={keyResultStats}
				ring2Percentages={blockerStats}
			/>
			{false &&
				GoalsService.getMyProfile()
					.projects[0].goals.filter((g: IProjectGoal) => GoalsService.shouldGoalBeDisplayed(g, activeDate))
					.map((g) => {
						return (
							<div key={g.id}>
								<Header>{g.goal.name}</Header>
								{GoalsService.getActionableTasksFromGoal(g.id).map((ag) => {
									return (
										<div
											key={ag.id}
											className="goal-active-task"
											onClick={() => {
												GoalsService.touchGoal(ag.id, activeDate);
												setUpdate(update + 1);
											}}
										>
											{ag.goal.type === GoalType.BadHabit && (
												<Icon
													className={
														GoalsService.wasCompletedOnDate(ag, activeDate)
															? 'completed'
															: ''
													}
													color="red"
													name="thumbs down"
												/>
											)}
											{ag.goal.type === GoalType.GoodHabit && (
												<Icon
													className={
														GoalsService.wasCompletedOnDate(ag, activeDate)
															? 'completed'
															: ''
													}
													color="green"
													name="thumbs up"
												/>
											)}
											{ag.goal.type === GoalType.OneTimeTask && (
												<Icon
													className={
														GoalsService.wasCompletedOnDate(ag, activeDate)
															? 'completed'
															: ''
													}
													color="black"
													name="check"
												/>
											)}
											{ag.goal.type === GoalType.Target && (
												<Icon
													className={
														GoalsService.wasCompletedOnDate(ag, activeDate)
															? 'completed'
															: ''
													}
													color="black"
													name="bullseye"
												/>
											)}
											{ag.goal.name}
										</div>
									);
								})}
							</div>
						);
					})}
			<GoalTree
				goals={userProfile.projects[0].goals.filter((g: IProjectGoal) =>
					GoalsService.shouldGoalBeDisplayed(g, activeDate)
				)}
				onUpdate={() => {
					setUpdate(update + 1);
				}}
				date={activeDate}
			/>
			<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
				{userProfile.projects[0].goals
					.filter((g: IProjectGoal) => GoalsService.shouldGoalBeDisplayed(g, activeDate))
					.map((g, gindex) => {
						return (
							<div key={`goal-${gindex}`}>
								<Header>{g.goal.name}</Header>
								<Droppable
									isDropDisabled={disabledDroppers[gindex]}
									droppableId={g.id}
									isCombineEnabled
								>
									{(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
										return (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver)}
											>
												{g.hows
													.filter((h: IProjectGoal) =>
														GoalsService.shouldGoalBeDisplayed(h, activeDate)
													)
													.map((ag: any, index: number) => (
														<Draggable key={ag.id} draggableId={ag.id} index={index}>
															{(provided: DraggableProvided, snapshot: any) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																>
																	{ag.goal.type === GoalType.BadHabit && (
																		<Icon
																			className={
																				GoalsService.wasCompletedOnDate(
																					ag,
																					activeDate
																				)
																					? 'completed'
																					: ''
																			}
																			name="thumbs down"
																		/>
																	)}
																	{ag.goal.type === GoalType.GoodHabit && (
																		<Icon
																			className={
																				GoalsService.wasCompletedOnDate(
																					ag,
																					activeDate
																				)
																					? 'completed'
																					: ''
																			}
																			name="thumbs up"
																		/>
																	)}
																	{ag.goal.type === GoalType.OneTimeTask && (
																		<Icon
																			className={
																				GoalsService.wasCompletedOnDate(
																					ag,
																					activeDate
																				)
																					? 'completed'
																					: ''
																			}
																			name="check"
																		/>
																	)}
																	{ag.goal.type === GoalType.Target && (
																		<Icon
																			className={
																				GoalsService.wasCompletedOnDate(
																					ag,
																					activeDate
																				)
																					? 'completed'
																					: ''
																			}
																			name="bullseye"
																		/>
																	)}
																	{ag.goal.type === GoalType.Objective && (
																		<Icon
																			className={
																				GoalsService.wasCompletedOnDate(
																					ag,
																					activeDate
																				)
																					? 'completed'
																					: ''
																			}
																			name="flag checkered"
																		/>
																	)}
																	{ag.goal.name}
																</div>
															)}
														</Draggable>
													))}
												{provided.placeholder}
											</div>
										);
									}}
								</Droppable>
							</div>
						);
					})}
			</DragDropContext>
		</Container>
	);
}
