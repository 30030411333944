import React from 'react';
import { Container } from 'semantic-ui-react';
import { Quote } from '../../components/component-quote';
import './view-landing.scss';

export function Landing() {
    return (
        <Container>
            <Quote />
        </Container>
    );
}