/* eslint-disable no-extra-bind */
import React from 'react';
import { Button, Modal, Icon, Form } from 'semantic-ui-react';
import './component-modal-new-goal.scss';
import { GoalType, IProjectGoal } from '../../data/interfaces';
import { GoalsService } from '../../services/goals-service';
import { DateInput } from 'semantic-ui-calendar-react';

enum ModalGoalTypes {
	None,
	Objective,
	OneTimeTask,
	Habit,
	Target
}

enum ModalHabitTypes {
	GoodHabit,
	BadHabit
}

enum ModalNewGoalStep {
	Define,
	Details
}

interface IModalNewGoalState {
	open: boolean;
	step: ModalNewGoalStep;
	type: ModalGoalTypes;
	goalName: string;
	showHabitTypes: boolean;
	showTargets: boolean;
	habitType?: ModalHabitTypes;
	startValue?: number;
	goalValue?: number;
	goalDate?: string;
}

export interface IModalNewGoalProps {
	parentGoal: IProjectGoal;
	onUpdate: () => void;
}

export class ModalNewGoal extends React.Component<IModalNewGoalProps, IModalNewGoalState> {
	constructor(props: any) {
		super(props);
		this.state = {
			open: false,
			step: ModalNewGoalStep.Define,
			goalName: '',
			showHabitTypes: false,
			showTargets: false,
			type: ModalGoalTypes.None,
			startValue: 0
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event: any /* React.ChangeEvent<HTMLInputElement> */, value?: any) {
		if (value) {
			if (value.name === 'type') {
				switch (value.value) {
					case 'objective':
						this.setState({ type: ModalGoalTypes.Objective, showHabitTypes: false, showTargets: false });
						break;
					case 'habit':
						this.setState({ type: ModalGoalTypes.Habit, showHabitTypes: true, showTargets: false });
						break;
					case 'oneTimeTask':
						this.setState({ type: ModalGoalTypes.OneTimeTask, showHabitTypes: false, showTargets: false });
						break;
					case 'target':
						this.setState({ type: ModalGoalTypes.Target, showHabitTypes: false, showTargets: true });
						break;
				}
			} else if (value.name === 'habit-type') {
				switch (value.value) {
					case 'good-habit':
						this.setState({ habitType: ModalHabitTypes.GoodHabit });
						break;
					case 'bad-habit':
						this.setState({ habitType: ModalHabitTypes.BadHabit });
						break;
				}
			} else if (value.name === 'endDate') {
				this.setState({ goalDate: value.value });
			}
		} else {
			const fieldName: string = event.target.attributes.getNamedItem('name')?.nodeValue ?? '';
			const updatedStateObject = function () {
				const returnObj: any = {};
				returnObj[fieldName] = event.target.value;
				return returnObj;
			}.bind(event)();
			this.setState(updatedStateObject);
		}
	}

	handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		const goalEndDate: Date | undefined = this.state.goalDate ? new Date(this.state.goalDate) : undefined;
		switch (this.state.type) {
			case ModalGoalTypes.None:
				break;
			case ModalGoalTypes.Objective:
				GoalsService.addSimpleNewSubGoalTo(
					this.props.parentGoal,
					this.state.goalName,
					GoalType.Objective,
					goalEndDate
				);
				break;
			case ModalGoalTypes.Habit:
				GoalsService.addSimpleNewSubGoalTo(
					this.props.parentGoal,
					this.state.goalName,
					this.state.habitType === ModalHabitTypes.BadHabit ? GoalType.BadHabit : GoalType.GoodHabit,
					goalEndDate
				);
				break;
			case ModalGoalTypes.OneTimeTask:
				GoalsService.addSimpleNewSubGoalTo(
					this.props.parentGoal,
					this.state.goalName,
					GoalType.OneTimeTask,
					goalEndDate
				);
				break;
			case ModalGoalTypes.Target:
				GoalsService.addSimpleNewSubGoalTo(
					this.props.parentGoal,
					this.state.goalName,
					GoalType.Target,
					goalEndDate,
					this.state.startValue,
					this.state.goalValue
				);
				break;
		}
		this.props.onUpdate();
		event.preventDefault();
	}

	setOpen(state: boolean) {
		this.setState({
			goalName: '',
			step: ModalNewGoalStep.Define,
			type: ModalGoalTypes.None,
			showHabitTypes: false,
			showTargets: false,
			open: state,
			startValue: 0,
			goalValue: undefined,
			goalDate: undefined,
			habitType: undefined
		});
	}

	nextStep() {
		this.setState({
			step: ModalNewGoalStep.Details
		});
	}

	previousStep() {
		this.setState({
			step: ModalNewGoalStep.Define
		});
	}

	isFormReady(): boolean {
		if (this.state.goalName.trim().length >= 3 && this.state.type !== ModalGoalTypes.None) {
			if (this.state.type === ModalGoalTypes.Habit && this.state.habitType !== undefined) {
				return true;
			}
			if (
				this.state.type === ModalGoalTypes.Target &&
				this.state.goalValue !== undefined &&
				this.state.startValue !== undefined
			) {
				return true;
			}
			if (this.state.type === ModalGoalTypes.OneTimeTask || this.state.type === ModalGoalTypes.Objective) {
				return true;
			}
		}
		return false;
	}

	render() {
		return (
			<Modal
				onClose={() => this.setOpen(false)}
				onOpen={() => this.setOpen(true)}
				open={this.state.open}
				trigger={
					<Button floated="right">
						<Icon name="plus" />
						Add sub-goal
					</Button>
				}
			>
				{this.state.step === ModalNewGoalStep.Define && (
					<>
						<Modal.Header>New goal</Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Field>
									<label>What is your goal?</label>
									<input
										name="goalName"
										value={this.state.goalName}
										onChange={this.handleChange}
										placeholder="Briefly describe your goal"
									/>
								</Form.Field>
								<Form.Group grouped>
									<label>Type of goal</label>
									<Form.Radio
										name="type"
										label="Objective"
										value="objective"
										checked={this.state.type === ModalGoalTypes.Objective}
										onChange={this.handleChange}
									/>
									<Form.Radio
										name="type"
										label="One-time task"
										value="oneTimeTask"
										checked={this.state.type === ModalGoalTypes.OneTimeTask}
										onChange={this.handleChange}
									/>
									<Form.Radio
										name="type"
										label="Habit"
										value="habit"
										checked={this.state.type === ModalGoalTypes.Habit}
										onChange={this.handleChange}
									/>
									<Form.Radio
										name="type"
										label="Target goal"
										value="target"
										checked={this.state.type === ModalGoalTypes.Target}
										onChange={this.handleChange}
									/>
								</Form.Group>
							</Form>
						</Modal.Content>
					</>
				)}
				{this.state.step === ModalNewGoalStep.Details && (
					<>
						<Modal.Header>Goal details</Modal.Header>
						<Modal.Content>
							<Form>
								{this.state.showHabitTypes && (
									<Form.Group grouped>
										<label>What type of habit</label>
										<Form.Radio
											name="habit-type"
											label="Good habit"
											value="good-habit"
											checked={this.state.habitType === ModalHabitTypes.GoodHabit}
											onChange={this.handleChange}
										/>
										<Form.Radio
											name="habit-type"
											label="Bad habit"
											value="bad-habit"
											checked={this.state.habitType === ModalHabitTypes.BadHabit}
											onChange={this.handleChange}
										/>
									</Form.Group>
								)}
								{this.state.showTargets && (
									<>
										<Form.Field>
											<label>Start value</label>
											<input
												type="number"
												name="startValue"
												value={this.state.startValue}
												onChange={this.handleChange}
												placeholder="0"
											/>
										</Form.Field>
										<Form.Field>
											<label>Goal value</label>
											<input
												type="number"
												name="goalValue"
												value={this.state.goalValue}
												onChange={this.handleChange}
												placeholder="0"
											/>
										</Form.Field>
									</>
								)}
								<Form.Field>
									<label>End date</label>
									<DateInput
										name="endDate"
										dateFormat="l"
										placeholder="Date"
										value={this.state.goalDate?.toString() ?? ''}
										iconPosition="left"
										onChange={this.handleChange}
									/>
								</Form.Field>
							</Form>
						</Modal.Content>
					</>
				)}

				<Modal.Actions>
					<Button color="black" onClick={() => this.setOpen(false)}>
						Cancel
					</Button>
					{this.state.step === ModalNewGoalStep.Define && (
						<Button
							disabled={this.state.goalName.trim().length <= 3 || this.state.type === ModalGoalTypes.None}
							content="Next"
							labelPosition="right"
							icon="arrow right"
							onClick={(event: any) => {
								this.nextStep();
							}}
							positive
						/>
					)}
					{this.state.step === ModalNewGoalStep.Details && (
						<>
							<Button
								disabled={this.state.goalName.trim().length <= 3}
								content="Back"
								labelPosition="left"
								icon="arrow left"
								onClick={(event: any) => {
									this.previousStep();
								}}
							/>
							<Button
								type="submit"
								disabled={!this.isFormReady()}
								content="Add goal"
								labelPosition="right"
								icon="checkmark"
								onClick={(event: any) => {
									this.handleSubmit(event);
									this.setOpen(false);
								}}
								positive
							/>
						</>
					)}
				</Modal.Actions>
			</Modal>
		);
	}
}
