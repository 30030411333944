export interface IGoal {
	id: string;
	name: string;
	type: GoalType;
	description?: string;
	thread?: IThread;
	resources?: IResource[];
	quotes?: IQuote[];
}

export interface IThread {
	id: string;
}

export interface IResource {
	id: string;
	type: ResourceType;
	location: string;
	name: string;
}

export enum ResourceType {
	Link,
	Video,
	Image
}

export interface IQuote {
	id: string;
	quote: string;
}

export enum DueType {
	TargetDate,
	Recurring
}

export enum RecurrenceType {
	None,
	Hourly,
	Daily,
	Weekdays,
	Weekends,
	Weekly,
	Monthly,
	Yearly
}

export interface IProjectGoalDueBy {
	type: DueType;
	dueDate: Date;
	recurrance: RecurrenceType;
	recurranceInterval: number;
}

export interface IProjectGoalMetric {
	target: number;
	currentStatus: number;
	metricUnit: string;
}

export interface IProjectCompletion {
	completedDate: Date;
}

export enum GoalType {
	Objective,
	OneTimeTask,
	GoodHabit,
	BadHabit,
	Target
}

export enum GoalEventType {
	SingleEvent,
	NewValueEvent
}

export interface IGoalEvent {
	id: string;
	type: GoalEventType;
	date: Date;
	value?: number;
}

export interface IProjectGoal {
	id: string;
	goal: IGoal;
	created: Date;
	hows: IProjectGoal[];
	completed: boolean;
	archived: boolean;
	startValue?: number;
	goalValue?: number;
	goalDate?: Date;
	archivedDate?: Date;
	due?: IProjectGoalDueBy;
	metric?: IProjectGoalMetric;
	thread?: IThread;
	resources?: IResource[];
	history?: IGoalEvent[];
}

export interface IProject {
	id: string;
	name: string;
	goals: IProjectGoal[];
}

export interface IPublicUserProfile {
	id: string;
	name: string;
	avatar?: string;
	projects: IProject[];
}

export interface IDayStats {
	day: Date;
	completedBlockers: IProjectGoal[];
	completedKeyResults: IProjectGoal[];
	pendingBlockers: IProjectGoal[];
	pendingKeyResults: IProjectGoal[];
}
