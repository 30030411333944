import React, { useState } from 'react';
import { Container, Header, Button } from 'semantic-ui-react';
import './view-dreams.scss';
import { GoalList } from '../../components/component-goal-list';
import { GoalsService } from '../../services/goals-service';

export function Dreams() {
	const [update, setUpdate] = useState(0);
	const userProfile = GoalsService.getMyProfile();
	return (
		<Container className="dreams">
			<Button
				onClick={() => {
					GoalsService.resetMockData();
					setUpdate(update + 1);
				}}
			>
				Reset
			</Button>
			<Header as="h1">
				{userProfile.name}'s {userProfile.projects[0].name.toLowerCase()}
			</Header>
			<GoalList goals={GoalsService.getMyProfile().projects[0].goals}></GoalList>
		</Container>
	);
}
